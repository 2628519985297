/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container" style="height: 100%">
    <div class="email-tutorial-container" style="display: flex">
      <video style="width: 100%" controls v-if="emailServiceValue === 'AOL'">
        <source src="@/assets/video/email/aol.mp4" type="video/mp4" />
        Tu navegador no soporta la reproducción de video
      </video>

      <video
        style="width: 100%"
        controls
        v-if="emailServiceValue === 'Gmail' && !gmailSecure"
      >
        <source
          src="@/assets/video/email/gmail_no_secure.mp4"
          type="video/mp4"
        />
        Tu navegador no soporta la reproducción de video
      </video>

      <video
        style="width: 100%"
        controls
        v-if="emailServiceValue === 'Gmail' && gmailSecure"
      >
        <source src="@/assets/video/email/gmail_secure.mp4" type="video/mp4" />
        Tu navegador no soporta la reproducción de video
      </video>

      <video style="width: 100%" controls v-if="emailServiceValue === 'iCloud'">
        <source src="@/assets/video/email/iCloud.mp4" type="video/mp4" />
        Tu navegador no soporta la reproducción de video
      </video>

      <video style="width: 100%" controls v-if="emailServiceValue === 'Yahoo'">
        <source src="@/assets/video/email/yahoo.mp4" type="video/mp4" />
        Tu navegador no soporta la reproducción de video
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailPasswordTutorialModal",
  props: ["emailService", "googleTwoSteps"],
  components: {},
  data() {
    return {
      emailServiceValue: this.emailService,
      gmailSecure: this.googleTwoSteps,
    };
  },
  async created() {},
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
