<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title print-hide">Venta de muestra</h1>

    <div class="container-print" ref="saleQuote">
      <img
        v-if="companyInformation.LOGO_URL"
        :src="companyInformation.LOGO_URL"
        alt="Company logo"
        style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
      />
      <p class="special-margin">
        <strong>
          {{
            companyInformation.LEYENDA_SUPERIOR
              ? companyInformation.LEYENDA_SUPERIOR
              : ""
          }}</strong
        >
      </p>
      <table width="100%">
        <tr class="logotype-container">
          <td>
            <div class="logotype-type" :style="[backgroundStyle, textStyle]">
              Venta de muestra
            </div>
          </td>
        </tr>
      </table>
      <br />

      <div style="margin-bottom: 20px">
        <strong>Información de la empresa</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <strong>Nombre:</strong>
                  ESCUELA KEMPER URGATE <br />
                  <strong>Almacén origen:</strong>
                  General
                  <br />
                  <strong>Dirección:</strong>
                  Paseo de la rosa #445 Guadalajara, Jalisco 45116<br />
                  <strong>Teléfono: </strong>
                  0000000000
                  <br />
                  <strong>Correo: </strong>
                  correo@escuelakemper.com
                  <br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <div
        style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Información del cliente</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td style="padding: 10px; width: 50%">
            <strong>Nombre:</strong>
            UNIVERSIDAD ROBOTICA ESPAÑOLA<br />
            <strong>Fecha:</strong>
            12-01-2022<br />
            <strong>Dirección:</strong>
            Paseo de la roja #556 Jalisco, Guadalajara 45116<br />
          </td>
          <td style="padding: 20px; width: 50%">
            <strong>#venta:</strong>
            115<br />
            <strong>Email:</strong>
            correo@universidadespañola.com<br />
            <strong>Télefono:</strong>
            0000000000<br />
          </td>
        </tr>
      </table>
      <br />
      <div class="table-overflow">
        <table
          width="100%"
          style="border-collapse: collapse; border-bottom: 1px solid #eee"
          v-if="!printWithoutPrices"
        >
          <tr>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              #
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Clave
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Artículo
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Costo
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              IVA
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              IEPS
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Costo Final
            </td>
          </tr>
          <div class="display-content">
            <tr class="article-element">
              <td class="row">1</td>
              <td class="row">PIEZ1</td>
              <td class="row">
                Piezas de repuesto #415
                <br />
                <span style="color: #777; font-size: 11px"></span>
              </td>
              <td class="row">{{ 100 | money("MXN", 2) }}</td>
              <td class="row">{{ 16 }}%</td>
              <td class="row">{{ 0 }}%</td>
              <td class="row">{{ 1 }}</td>
              <td class="row">
                {{ 100 | money("MXN", 2) }}
              </td>
            </tr>
          </div>
        </table>
        <table
          width="100%"
          style="
            border-collapse: collapse;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          "
          v-if="printWithoutPrices"
        >
          <tr>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              #
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Clave
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Artículo
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad
            </td>
          </tr>
          <div class="display-content">
            <tr class="article-element">
              <td class="row">1</td>
              <td class="row">PIEZ1</td>
              <td class="row">
                Piezas de repuesto #415
                <br />
                <span style="color: #777; font-size: 11px"></span>
              </td>
              <td class="row">1</td>
            </tr>
          </div>
        </table>
        <br />
      </div>
      <table
        width="100%"
        style="padding: 20px; border-radius: 5px"
        v-if="!printWithoutPrices"
        class="not-cut-printing"
      >
        <tr>
          <td
            style="padding-top: 1rem; padding-bottom: 1rem; padding-right: 2%"
          >
            <table width="300px" style="float: right">
              <tr>
                <td>
                  <strong>Indirectos:</strong>
                </td>
                <td style="text-align: right">
                  {{ 0 | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Descuentos:</strong>
                </td>
                <td style="text-align: right">
                  {{ 0 | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Sub-total:</strong></td>
                <td style="text-align: right">
                  {{ 100 | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>IVA:</strong></td>
                <td style="text-align: right">
                  {{ 16 | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>IEPS:</strong></td>
                <td style="text-align: right">
                  {{ 0 | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Total:</strong></td>
                <td style="text-align: right">
                  {{ 116 | money("MXN", 2) }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div
        class="alert not-cut-printing"
        style="border-radius: 5px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Observaciones</strong> <br />
        <br />
        <b-input
          v-if="!printing"
          type="textarea"
          value="Obsevaciones de prueba"
        ></b-input>
        <div
          class="observations-element observations-element-printing not-cut-printing"
          v-if="printing"
          style="background-color: white; padding: 0.625em; min-height: 120px"
        >
          <p>Obsevaciones de prueba</p>
        </div>
      </div>
      <p class="special-margin-top not-cut-printing">
        <strong>
          {{
            companyInformation.LEYENDA_INFERIOR
              ? companyInformation.LEYENDA_INFERIOR
              : ""
          }}</strong
        >
      </p>
      <div v-if="printing" style="margin-top: 1.5rem" class="not-cut-printing">
        Este pdf fue impreso por coconutcontrol.com
      </div>
    </div>
    <div class="container-print">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print(0)"
          >Descargar sin precios</b-button
        >
        <b-button type="is-success" @click="print(1)"
          >Descargar con precios</b-button
        >

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { openConfirmationModalSucces } from "@/utils/component-fns";
import { hexToRGB, transformFileToBase64 } from "@/utils/fns";

// @ is an alias to /src
export default {
  name: "PrintTestingFormat",
  props: [],
  components: {},
  data() {
    return {
      printing: false,
      printWithoutPrices: false,
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {
    transformFileToBase64,
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print(type) {
      this.createPdfHtml(type, "print");
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 500);
    },
    /**
     *
     * @desc cretes or generates a pdf file
     */
    async createPdfHtml(type, action) {
      if (type === 0) {
        this.printWithoutPrices = true;
      } else {
        this.printWithoutPrices = false;
      }
      this.printing = true;
      let pdfOptions = {
        filename: `archivo de muestra ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".not-cut-printing",
          ],
        },
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
        scale: 2,
      };
      let result = await html2pdf()
        .set(pdfOptions)
        .from(this.$refs.saleQuote)
        .save()
        .output("datauristring");
      this.printing = false;
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
    permissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
  },
  watch: {
    companyEmails() {
      this.selectedEmail = this.companyEmails[0]._id;
    },
  },
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
