/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!companyNameInformation">
      Agregar razón social extra
    </h1>

    <div v-if="!companyNameInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado.</span> Una vez registrada la
      razón social extra, podrás usarla para timbrar ventas
    </div>

    <div class="control add-global-controls special-margin">
      <b-field class="file is-primary upload-button">
        <b-upload
          class="file-label"
          accept=".cer"
          @input="(value) => transformCertificate(value)"
        >
          <span class="file-cta">
            <span class="file-label"> Subir certificado SAT</span>
          </span>
        </b-upload>
      </b-field>

      <b-field class="file is-primary upload-button">
        <b-upload
          class="file-label"
          accept=".key"
          @input="(value) => transformKey(value)"
        >
          <span class="file-cta">
            <span class="file-label"> Subir llave privada SAT</span>
          </span>
        </b-upload>
      </b-field>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCompanyNameRequiredErrors.NOMBRE_FAC }"
        :message="{
          'La razón social sin régimen no es válida':
            newCompanyNameRequiredErrors.NOMBRE_FAC,
        }"
      >
        <template slot="label">
          Razón social sin tipo de sociedad (Requerido)
          <b-tooltip
            multilined
            label="Si su compañía es 'Mi compañía S.A. de C.V.' poner solo 'Mi compañía'"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: Compañía"
          expanded
          icon="file-outline"
          v-model="newCompanyName.NOMBRE_FAC"
          @input="
            (val) =>
              (newCompanyName.NOMBRE_FAC =
                newCompanyName.NOMBRE_FAC.toUpperCase())
          "
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="RFC de la compañía (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: XAXX010101000"
          expanded
          icon="file-outline"
          v-model="newCompanyName.RFC"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Régimen fiscal (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCompanyNameRequiredErrors.CLAVE_REGIMEN }"
        :message="{
          'El régimen fical no es valido':
            newCompanyNameRequiredErrors.CLAVE_REGIMEN,
        }"
      >
        <b-select
          placeholder="Ejemplo: Régimen fiscal"
          icon="briefcase-outline"
          expanded
          v-model="newCompanyName.CLAVE_REGIMEN"
          required
          class="required-input"
        >
          <option value="" disabled>Seleccione un régimen</option>
          <option
            v-for="singleRegimen in regimenes"
            :value="singleRegimen._id"
            :key="singleRegimen._id"
          >
            {{ singleRegimen.DESCRIPCION }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCompanyNameRequiredErrors.CERTIFICADO_SAT }"
        :message="{
          'El certificado SAT en base 64 no es válido':
            newCompanyNameRequiredErrors.CERTIFICADO_SAT,
        }"
      >
        <template slot="label">
          Certificado SAT en base 64 (Requerido)
          <b-tooltip
            multilined
            label="Si no cuenta con su certificado SAT en base 64 puede subir el archivo .cer en el botón 'Subir certificado SAT' y nosotros transformaremos el certificado a base 64"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: MIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQw..."
          expanded
          icon="directions-fork"
          v-model="newCompanyName.CERTIFICADO_SAT"
          password-reveal
          type="password"
          required
        ></b-input>
      </b-field>

      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCompanyNameRequiredErrors.LLAVE_SAT }"
        :message="{
          'La llave SAT en base 64 no es válida':
            newCompanyNameRequiredErrors.LLAVE_SAT,
        }"
      >
        <template slot="label">
          Llave SAT en base 64 (Requerido)
          <b-tooltip
            multilined
            label="Si no cuenta con su llave privada SAT en base 64 puede subir el archivo .key en el botón 'Subir llave privada SAT' y nosotros transformaremos la llave a base 64"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: MIIFuzCCA6OgAwIBAgIUMzAwMDEwMDAwMDA0MDAwMDI..."
          expanded
          icon="directions-fork"
          v-model="newCompanyName.LLAVE_SAT"
          password-reveal
          type="password"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de llave privada SAT (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCompanyNameRequiredErrors.CLAVE_LLAVE }"
        :message="{
          'La clave de llave privada SAT no es válida':
            newCompanyNameRequiredErrors.CLAVE_LLAVE,
        }"
      >
        <b-input
          placeholder="Ejemplo: 1234ABC"
          expanded
          icon="file-outline"
          v-model="newCompanyName.CLAVE_LLAVE"
          password-reveal
          type="password"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCompanyNameRequiredErrors.CP }"
        :message="{
          'El código postal no es válido': newCompanyNameRequiredErrors.CP,
        }"
      >
        <template slot="label">
          Código Postal (Requerido)
          <b-tooltip
            multilined
            label="Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 00000"
          expanded
          icon="directions-fork"
          v-model="newCompanyName.CP"
          v-cleave="masks.customCP"
          @input="(value) => searchForDirectionInformation(value)"
          type="number"
          step="any"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="saveCompanyName(0)"
        v-if="!companyNameInformation"
      >
        Añadir razón social extra
      </b-button>

      <b-button
        type="is-success"
        @click="saveCompanyName(1)"
        v-if="companyNameInformation"
      >
        Actualizar razón social extra
      </b-button>

      <b-button
        type="is-success"
        @click="activateCompanyName()"
        v-if="companyNameInformation && companyNameInformation.STATUS === 'PE'"
      >
        Activar razón social extra
      </b-button>

      <b-button
        type="is-danger"
        class="cancel-button"
        @click="disableCompanyName()"
        v-if="companyNameInformation && companyNameInformation.STATUS !== 'PE'"
      >
        Desactivar razón social extra
      </b-button>

      <b-button
        type="is-danger"
        @click="closeModal()"
        class="cancel-button"
        v-if="!companyNameInformation"
      >
        Regresar
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cleave from "cleave.js";
import moment from "moment";
import {
  checkValidationErrors,
  validateFields,
  transformFileToBase64,
} from "@/utils/fns";
import { fillObjectForm } from "@/utils/component-fns";
import States from "../../../data/states";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddEmailModal",
  props: ["companyNameInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      moment: moment,
      newCompanyName: {
        RFC: "",
        CLAVE_REGIMEN: "",
        NOMBRE_FAC: "",
        CERTIFICADO_SAT: "",
        LLAVE_SAT: "",
        CLAVE_LLAVE: "",
        CP: "",
      },
      newCompanyNameRequiredErrors: {
        NOMBRE_FAC: false,
        CLAVE_REGIMEN: false,
        CERTIFICADO_SAT: false,
        LLAVE_SAT: false,
        CLAVE_LLAVE: false,
        CP: false,
      },
      masks: Masks,
      states: States,
      currentColonies: [],
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.companyNameInformation) {
      // Fill the form with received information
      fillObjectForm(this.companyNameInformation, this.newCompanyName);

      this.newCompanyName._id = this.companyNameInformation._id;
      this.newCompanyName.STATUS = this.companyNameInformation.STATUS;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveCompanyName(type) {
      try {
        this.newCompanyNameRequiredErrors = validateFields(
          this.newCompanyName,
          this.newCompanyNameRequiredErrors
        );
        if (checkValidationErrors(this.newCompanyNameRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVECOMPANYNAME" : "EDITCOMPANYNAME",
            this.newCompanyName
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la razón social extra",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la razón social extra en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la razón social extra en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la razón social extra en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc searches for direction information when zip has the corret format
     */
    async searchForDirectionInformation(zipCode) {
      if (zipCode.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          { zipCode }
        );
        if (response.length > 0) {
          this.newCompanyName.ESTADO = response[0].state_code;
          this.newCompanyName.NOMBRE_MUNICIPIO = response[0].municipality;
          this.newCompanyName.MUNICIPIO = response[0].municipio_code;
          this.newCompanyName.LOCALIDAD = response[0].localidad_code;
          this.newCompanyName.NOMBRE_COLONIA = response[0].colony;
          this.newCompanyName.COLONIA = response[0].colony_code;
          this.currentColonies = response;
        }
      }
    },
    async transformCertificate(file) {
      const transformedFile = await transformFileToBase64(file);
      const result = transformedFile.split("base64,");
      this.newCompanyName.CERTIFICADO_SAT = result[1];
    },
    async transformKey(file) {
      const transformedFile = await transformFileToBase64(file);
      const result = transformedFile.split("base64,");
      this.newCompanyName.LLAVE_SAT = result[1];
    },
    async activateCompanyName() {
      try {
        let response = await this.$store.dispatch("ACTIVATECOMPANYNAME", {
          companyNameId: this.newCompanyName._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se activó exitosamente la razón social extra",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al activar la razón social extra en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al activar la razón social extra en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async disableCompanyName() {
      try {
        let response = await this.$store.dispatch("DISABLECOMPANYNAME", {
          companyNameId: this.newCompanyName._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se desactivó exitosamente la razón social extra",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al desactivar la razón social extra en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al desactivar la razón social extra en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    filteredColonies() {
      return this.currentColonies.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(this.newCompanyName.NOMBRE_COLONIA.toLowerCase()) >= 0
        );
      });
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        return regimenWithProps;
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
