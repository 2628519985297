const states = [
  {
    CLAVE: "AGU",
    PAIS: "MEX",
    NOMBRE: "Aguascalientes",
  },
  {
    CLAVE: "BCN",
    PAIS: "MEX",
    NOMBRE: "Baja California",
  },
  {
    CLAVE: "BCS",
    PAIS: "MEX",
    NOMBRE: "Baja California Sur",
  },
  {
    CLAVE: "CAM",
    PAIS: "MEX",
    NOMBRE: "Campeche",
  },
  {
    CLAVE: "CHP",
    PAIS: "MEX",
    NOMBRE: "Chiapas",
  },
  {
    CLAVE: "CHH",
    PAIS: "MEX",
    NOMBRE: "Chihuahua",
  },
  {
    CLAVE: "COA",
    PAIS: "MEX",
    NOMBRE: "Coahuila",
  },
  {
    CLAVE: "COL",
    PAIS: "MEX",
    NOMBRE: "Colima",
  },
  {
    CLAVE: "CMX",
    PAIS: "MEX",
    NOMBRE: "Ciudad de México",
  },
  {
    CLAVE: "DUR",
    PAIS: "MEX",
    NOMBRE: "Durango",
  },
  {
    CLAVE: "GUA",
    PAIS: "MEX",
    NOMBRE: "Guanajuato",
  },
  {
    CLAVE: "GRO",
    PAIS: "MEX",
    NOMBRE: "Guerrero",
  },
  {
    CLAVE: "HID",
    PAIS: "MEX",
    NOMBRE: "Hidalgo",
  },
  {
    CLAVE: "JAL",
    PAIS: "MEX",
    NOMBRE: "Jalisco",
  },
  {
    CLAVE: "MEX",
    PAIS: "MEX",
    NOMBRE: "México",
  },
  {
    CLAVE: "MIC",
    PAIS: "MEX",
    NOMBRE: "Michoacán",
  },
  {
    CLAVE: "MOR",
    PAIS: "MEX",
    NOMBRE: "Morelos",
  },
  {
    CLAVE: "NAC",
    PAIS: "MEX",
    NOMBRE: "Nacional",
  },
  {
    CLAVE: "NAY",
    PAIS: "MEX",
    NOMBRE: "Nayarit",
  },
  {
    CLAVE: "NLE",
    PAIS: "MEX",
    NOMBRE: "Nuevo León",
  },
  {
    CLAVE: "OAX",
    PAIS: "MEX",
    NOMBRE: "Oaxaca",
  },
  {
    CLAVE: "PUE",
    PAIS: "MEX",
    NOMBRE: "Puebla",
  },
  {
    CLAVE: "QUE",
    PAIS: "MEX",
    NOMBRE: "Querétaro",
  },
  {
    CLAVE: "ROO",
    PAIS: "MEX",
    NOMBRE: "Quintana Roo",
  },
  {
    CLAVE: "SLP",
    PAIS: "MEX",
    NOMBRE: "San Luis Potosí",
  },
  {
    CLAVE: "SIN",
    PAIS: "MEX",
    NOMBRE: "Sinaloa",
  },
  {
    CLAVE: "SON",
    PAIS: "MEX",
    NOMBRE: "Sonora",
  },
  {
    CLAVE: "TAB",
    PAIS: "MEX",
    NOMBRE: "Tabasco",
  },
  {
    CLAVE: "TAM",
    PAIS: "MEX",
    NOMBRE: "Tamaulipas",
  },
  {
    CLAVE: "TLA",
    PAIS: "MEX",
    NOMBRE: "Tlaxcala",
  },
  {
    CLAVE: "VER",
    PAIS: "MEX",
    NOMBRE: "Veracruz",
  },
  {
    CLAVE: "YUC",
    PAIS: "MEX",
    NOMBRE: "Yucatán",
  },
  {
    CLAVE: "ZAC",
    PAIS: "MEX",
    NOMBRE: "Zacatecas",
  },
];

export default states;
