/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!userInformation">
      Agregar nuevo usuario
    </h1>
    <div v-if="!userInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado.</span> Los usuarios nuevos
      podran iniciar sesión con el nombre de la compañía, usuario y contraseña.
      <span class="global-form-hint-color"
        >No olvides agregar las listas de precios permitidas a los nuevos
        usuarios en el módulo de listas de precios</span
      >
    </div>

    <h3 class="form-division">
      Información general (No olvides agregar las listas de precios permitidas a
      los nuevos usuarios en el módulo de listas de precios)
    </h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': newUserRequiredErrors.name }"
        :message="{
          'El nombre no es valido': newUserRequiredErrors.name,
        }"
      >
        <b-input
          placeholder="Ejemplo: Ryan White"
          expanded
          icon="account"
          required
          v-model="newUser.name"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Email (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': newUserRequiredErrors.email }"
        :message="{
          'El email no es valido': newUserRequiredErrors.email,
        }"
      >
        <b-input
          placeholder="Ejemplo: ryan@mail.com"
          expanded
          icon="at"
          required
          v-model="newUser.email"
        />
      </b-field>
    </b-field>

    <b-field grouped class="no-margin">
      <b-field
        label="Contraseña (Opcional)"
        expanded
        label-position="inside"
        class="required-input"
        required
      >
        <b-input
          placeholder="Ejemplo: ry@n1_23"
          expanded
          type="password"
          password-reveal
          icon="account-lock"
          required
          v-model="newUser.password"
        />
      </b-field>
    </b-field>
    <div class="special-margin">
      <password-meter :password="newUser.password" />
    </div>

    <b-field grouped class="special-margin">
      <b-field
        label="Perfil de permisos (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        required
        :type="{ 'is-danger': newUserRequiredErrors.permissions }"
        :message="{
          'El servicio no es valido': newUserRequiredErrors.permissions,
        }"
      >
        <b-select
          placeholder="Ejemplo: Administrador"
          expanded
          icon="badge-account-outline"
          v-model="newUser.permissions"
        >
          <option value="" disabled>Seleccione un perfil de permisos</option>
          <option
            v-for="singlePermision in permissions"
            :value="singlePermision._id"
            :key="singlePermision._id"
          >
            {{ singlePermision.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin required-input">
      <b-field
        label="Almacenes permitidos (Requerido)"
        expanded
        label-position="inside"
      >
        <b-taginput
          :data="warehouses"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="NOMBRE_ALM"
          icon="warehouse"
          v-model="selectedWarehouses"
          @add="(value) => addWarehouse(value)"
          @remove="(value) => removeWarehouse(value)"
          ellipsis
          placeholder="Nombre de alamacen"
          aria-close-label="Borrar almacen seleccionado"
          check-infinite-scroll
        >
        </b-taginput>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="!userInformation">
      <b-button type="is-success" @click="saveUser()">
        Añadir usuario
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar
      </b-button>
    </div>

    <b-tooltip
      multilined
      label="El usuario de administrador no puede editarse"
      v-if="userInformation && userInformation.name === 'admin'"
    >
      <div class="add-global-controls">
        <b-button type="is-success" disabled> Actualizar usuario </b-button>
      </div>
    </b-tooltip>

    <div
      class="add-global-controls"
      v-if="userInformation && userInformation.name !== 'admin'"
    >
      <b-button type="is-success" @click="updateUser()">
        Actualizar usuario
      </b-button>

      <b-button
        type="is-success"
        @click="activateUser()"
        v-if="userInformation.STATUS === 'PE'"
      >
        Activar usuario
      </b-button>

      <b-button
        type="is-danger"
        class="cancel-button"
        @click="disableUser()"
        v-if="userInformation.STATUS === 'AC'"
      >
        Desactivar usuario
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { emailServices } from "../../../data/email-services";
import { fillObjectForm } from "../../../utils/component-fns";
import passwordMeter from "vue-simple-password-meter";

export default {
  name: "AddUserModal",
  props: ["userInformation"],
  components: {
    passwordMeter,
  },
  data() {
    return {
      emailServices: emailServices,
      moment: moment,
      newUser: {
        name: "",
        email: "",
        password: "",
        permissions: "",
        ALMACENES: [],
      },
      selectedWarehouses: [],
      newUserRequiredErrors: {
        name: false,
        email: false,
        permissions: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.userInformation) {
      // Fill the form with received information
      fillObjectForm(this.userInformation, this.newUser);
      // Fill permissions id
      if (this.userInformation.permissions)
        this.newUser.permissions = this.userInformation.permissions._id;
      if (this.userInformation._id) this.newUser._id = this.userInformation._id;

      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.selectedWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveUser() {
      try {
        this.newUserRequiredErrors = validateFields(
          this.newUser,
          this.newUserRequiredErrors
        );
        if (checkValidationErrors(this.newUserRequiredErrors)) {
          let response = await this.$store.dispatch("SAVEUSER", this.newUser);
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el usuario",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el usuario en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el usuario la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el usuario en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async updateUser() {
      try {
        this.newUserRequiredErrors = validateFields(
          this.newUser,
          this.newUserRequiredErrors
        );
        if (checkValidationErrors(this.newUserRequiredErrors)) {
          let response = await this.$store.dispatch("UPDATEUSER", this.newUser);
          if (response === "Success") {
            this.newUser.password = "";
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se actualizó exitosamente el usuario",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al actualizar el usuario en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
          let allPromises = [];
          allPromises.push(this.$store.dispatch("GETUSERS"));
          await Promise.all(allPromises);
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al actualizar el usuario la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al actualizar el usuario en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async activateUser() {
      try {
        let response = await this.$store.dispatch("ACTIVATEUSER", {
          userId: this.newUser._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se activó exitosamente el usuario",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al activar el usuario en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al activar el usuario en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async disableUser() {
      try {
        let response = await this.$store.dispatch("DISABLEUSER", {
          userId: this.newUser._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se desactivó exitosamente el usuario",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al desactivar el usuario en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al desactivar el usuario en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkValidWarehouses() {
      if (this.newUser.ALMACENES.lenght === 0) {
        return false;
      }
      return true;
    },
    addWarehouse(warehouse) {
      this.newUser.ALMACENES.push(warehouse._id);
    },
    removeWarehouse(warehouse) {
      for (const [
        singleWarehouseIndex,
        singleWarehouse,
      ] of this.newUser.ALMACENES.entries()) {
        if (singleWarehouse === warehouse._id) {
          this.newUser.ALMACENES.splice(singleWarehouseIndex, 1);
          return;
        }
      }
    },
  },
  computed: {
    permissions() {
      return this.$store.getters.PERMISSIONS.map((singlePermissions) => {
        const singlePermissionsWithProps = { ...singlePermissions };
        return singlePermissionsWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
  watch: {
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation && this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.selectedWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
