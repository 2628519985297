/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="addPermissions()"
          class="special-margin"
          >Agregar nuevo perfil de permisos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="openCopyPermissions()"
          class="special-margin"
          >Agregar nuevo perfil de permisos usando otro de base</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="deleteSelectedPermissions(checkedPermissions)"
          class="special-margin"
          >Borrar perfiles de permisos seleccionados</b-button
        >
      </div>
    </b-field>

    <div class="table-header">
      Cantidad de perfiles de permisos: <b>{{ permissions.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="permissions"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Permissions"
        :checked-rows.sync="checkedPermissions"
        checkable
        :is-row-checkable="
          (row) => {
            return row.name !== 'Admin' && row.numberOfUsers === 0;
          }
        "
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.name}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="name"
      >
        <template #empty>
          <div class="has-text-centered">No hay perfiles de permisos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <AddPermissionsModal :permissionsInformation="props.row" />
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import SelectPermissions from "./SelectPermissions";
import AddPermissionsModal from "./AddPermissionsModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "PermissionsList",
  components: {
    AddPermissionsModal,
  },
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      checkedPermissions: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      columns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "description",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "numberOfUsers",
          label: "Número de usuarios",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    addPermissions() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPermissionsModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async deleteSelectedPermissions(permissions) {
      try {
        if (permissions.length > 0) {
          let response = await this.$store.dispatch(
            "DELETEPERMISSIONS",
            permissions
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message:
                "Se borraron exitosamente los perfiles de permisos seleccionados",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al borrar los perfiles de permisos seleccionados",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar los perfiles de permisos seleccionados, seleccione uno o más perfiles de permisos para borrar",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al borrar los perfiles de permisos seleccionados",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openCopyPermissions() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectPermissions,
        props: {
          articles: this.articles,
          priceLists: [],
          isArticleList: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        return userWithProps;
      });
    },
    permissions() {
      return this.$store.getters.PERMISSIONS.map((singlePermissions) => {
        const permissionsWithProps = { ...singlePermissions };
        let numberOfUsers = 0;
        for (const singleUser of this.users) {
          if (singleUser.permissions._id === permissionsWithProps._id) {
            numberOfUsers += 1;
          }
        }
        permissionsWithProps.numberOfUsers = numberOfUsers;
        return permissionsWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
