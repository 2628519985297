/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!emailInformation">
      Agregar email
    </h1>

    <div v-if="!emailInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado.</span> Una vez registrada la
      cuenta de correo, podrás usarla para enviar mensajes a tus cliente y
      proveedores
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': newEmailAcountRequiredErrors.NOMBRE }"
        :message="{
          'El nombre no es valido': newEmailAcountRequiredErrors.NOMBRE,
        }"
      >
        <b-input
          placeholder="Ejemplo: 12345"
          expanded
          icon="email-plus-outline"
          required
          v-model="newEmailAcount.NOMBRE"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        class="required-input"
        required
        :type="{ 'is-danger': newEmailAcountRequiredErrors.SERVICIO }"
        :message="{
          'El servicio no es valido': newEmailAcountRequiredErrors.SERVICIO,
        }"
      >
        <template slot="label">
          Servicio (Requerido)
          <b-tooltip label="Proveedor donde se tiene el correo" multilined>
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-select
          placeholder="Ejemplo: Hotmail"
          expanded
          icon="email-open-multiple-outline"
          v-model="newEmailAcount.SERVICIO"
        >
          <option value="" disabled>Seleccione un servicio</option>
          <option
            v-for="service in emailServices"
            :value="service"
            :key="service"
          >
            {{ service }}
          </option>
          <option value="Otro">Dominio personalizado</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field
      grouped
      class="special-margin"
      v-if="newEmailAcount.SERVICIO === 'Otro'"
    >
      <b-field expanded label-position="inside">
        <template slot="label">
          Servidor SMTP (Requerido)
          <b-tooltip
            multilined
            label="Normalmente se encuentran de la siguiente forma 'mail.dominio.com'"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: smtp.servicio.com"
          expanded
          icon="email-search-outline"
          required
          v-model="newEmailAcount.SMTP"
        />
      </b-field>
      <b-field label="Puerto (Requerido)" expanded label-position="inside">
        <template slot="label">
          Puerto (Requerido)
          <b-tooltip
            multilined
            label="Usar el puerto 465 para hacer uso de encriptación SSL"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 465"
          expanded
          icon="email-outline"
          required
          v-model="newEmailAcount.PUERTO"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Correo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newEmailAcountRequiredErrors.CORREO }"
        :message="{
          'El correo no es valido': newEmailAcountRequiredErrors.CORREO,
        }"
      >
        <b-input
          placeholder="Ejemplo: 12345"
          expanded
          icon="at"
          required
          v-model="newEmailAcount.CORREO"
        />
      </b-field>
    </b-field>

    <b-field grouped>
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': newEmailAcountRequiredErrors.CONTRASEÑA }"
        :message="{
          'La contraseña no es valida': newEmailAcountRequiredErrors.CONTRASEÑA,
        }"
      >
        <template slot="label">
          Contraseña (Requerido)
          <b-tooltip
            multilined
            label="En algunos servicios es necesario crear una contraseña especial, si ese fuera el caso por favor siga las instrucciones que aparecen en el video"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 12345"
          expanded
          icon="email-lock"
          required
          type="password"
          password-reveal
          v-model="newEmailAcount.CONTRASEÑA"
        />
      </b-field>
    </b-field>

    <div
      v-bind:class="{
        'special-margin': newEmailAcount.CONTRASEÑA.length > 0,
      }"
    >
      <password-meter :password="newEmailAcount.CONTRASEÑA" />
    </div>

    <b-field
      grouped
      class="special-margin"
      v-if="newEmailAcount.SERVICIO === 'Gmail'"
    >
      <b-switch v-model="googleTwoSteps">
        Se tiene autenticación en dos pasos
      </b-switch>
    </b-field>

    <div v-if="tutorialActive(newEmailAcount.SERVICIO)" class="special-margin">
      <p class="global-form-hint">
        Para usar este proveedor es necesario crear una contraseña especial.
      </p>
      <span
        class="global-form-hint-color clickable-hint"
        @click="openTutorial()"
      >
        Haz click aqui para ver un tutorial de como crear una contraseña
        especial para este proveedor
      </span>
    </div>

    <div class="add-global-controls" v-if="!emailInformation">
      <b-button type="is-success" @click="saveEmailAcount()">
        Añadir cuenta de correo
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button">
        Regresar
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "@/utils/fns";
import { emailServices } from "@/data/email-services";
import { fillObjectForm } from "@/utils/component-fns";
import passwordMeter from "vue-simple-password-meter";
import EmailPasswordTutorialModal from "./EmailPasswordTutorialModal";

export default {
  name: "AddEmailModal",
  props: ["emailInformation"],
  components: {
    passwordMeter,
  },
  data() {
    return {
      emailServices: emailServices,
      moment: moment,
      googleTwoSteps: false,
      newEmailAcount: {
        NOMBRE: "",
        SERVICIO: "",
        SMTP: "",
        PUERTO: "465",
        CORREO: "",
        CONTRASEÑA: "",
      },
      newEmailAcountRequiredErrors: {
        NOMBRE: false,
        SERVICIO: false,
        CORREO: false,
        CONTRASEÑA: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.emailInformation) {
      // Fill the form with received information
      fillObjectForm(this.emailInformation, this.newEmailAcount);
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveEmailAcount() {
      try {
        this.newEmailAcountRequiredErrors = validateFields(
          this.newEmailAcount,
          this.newEmailAcountRequiredErrors
        );
        if (checkValidationErrors(this.newEmailAcountRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVECOMPANYEMAIL",
            this.newEmailAcount
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el correo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else if (response === "Cannot connect to email service provider") {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Error al conectar cuenta de correo, revise la validez de los campos ingresados",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el correo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el correo en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el correo en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    tutorialActive(emailService) {
      return (
        emailService === "AOL" ||
        emailService === "Gmail" ||
        emailService === "iCloud" ||
        emailService === "Yahoo"
      );
    },
    openTutorial() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EmailPasswordTutorialModal,
        props: {
          emailService: this.newEmailAcount.SERVICIO,
          googleTwoSteps: this.googleTwoSteps,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
