<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Configuración </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Información de la compañía" icon="account-box">
        <CompanyInformation />
      </b-tab-item>
      <b-tab-item label="Razones sociales extra" icon="domain">
        <CompanyNamesList />
      </b-tab-item>
      <b-tab-item label="Cuentas de correos" icon="account-child-outline">
        <EmailsList />
      </b-tab-item>
      <b-tab-item label="Usuarios" icon="account-multiple-plus">
        <UsersList />
      </b-tab-item>
      <b-tab-item label="Perfiles de permisos" icon="badge-account-outline">
        <PermissionsList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CompanyInformation from "../components/CompanyInformation.vue";
import CompanyNamesList from "../components/CompanyNamesList";
import EmailsList from "../components/EmailsList";
import UsersList from "../components/UsersList";
import PermissionsList from "../components/PermissionsList";

// @ is an alias to /src
export default {
  name: "ConfigurationComponents",
  components: {
    CompanyInformation,
    CompanyNamesList,
    EmailsList,
    UsersList,
    PermissionsList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "configuration");
  },
  async created() {
    let allPromises = [];

    if (this.$store.getters.SATREGIMENES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
    }
    allPromises.push(this.$store.dispatch("GETCOMPANYNAMES"));
    allPromises.push(this.$store.dispatch("GETPERMISSIONS"));
    allPromises.push(this.$store.dispatch("GETUSERS"));
    allPromises.push(this.$store.dispatch("GETCOMPANYNAMES"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
