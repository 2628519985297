let initialPermissions = {
  name: "",
  description: "",
  permissions: {
    banks: {
      allAccounts: "N",
      getBankAccounts: "N",
      saveBankAccounts: "N",
      saveBankAccountsMovements: "N",
      synchBankAccountsMovements: "N",
      saveBank: "N",
      getBanks: "N",
      saveBankMovementType: "N",
      getBankMovementType: "N",
      synchBankAccountBalance: "N",
      consolidateMovements: "N",
      editBanks: "N",
      cancelMovement: "N",
      changeShowInAccounts: "N",
    },
    global: {
      allConfiguration: "N",
      saveMainCategory: "N",
      getMainCategories: "N",
      saveSCategory: "N",
      getSCategories: "N",
      saveBrands: "N",
      getBrands: "N",
      sendEmailPurchaseOrder: "N",
      getCompanyEmails: "N",
      saveCompanyEmail: "N",
      deleteCompanyEmail: "N",
      getPermissions: "N",
      savePermissions: "N",
      updatePermissions: "N",
      deletePermissions: "N",
      getUsers: "N",
      saveUser: "N",
      updateUser: "N",
      deleteUser: "N",
      allVehicules: "N",
      getVehicules: "N",
      saveVehicule: "N",
      editMainCategories: "N",
      editSCategories: "N",
      editBrands: "N",
      editVehicules: "N",
      sendMaterialDelivery: "N",
      sendMaterialMovement: "N",
      sendEmailProfessionalServicesSale: "N",
      clientSupport: "N",
      allTransformations: "N",
      getFormulas: "N",
      saveFormula: "N",
      editFormula: "N",
      deleteFormula: "N",
      getArticleTransformation: "N",
      saveArticleTransformation: "N",
      cancelArticleTransformation: "N",
    },
    belvo: {
      connecToBelvo: "N",
    },
    main: {
      importData: "S",
      importClients: "N",
      importPricesListsArticles: "N",
      importPricesLists: "N",
      importWarehousesArticles: "N",
      importArticles: "N",
      importPresentations: "N",
      importProvidersPrices: "N",
      importProviders: "N",
      importBankMovements: "N",
      exportClients: "N",
      exportPricesListsArticles: "N",
      exportPricesLists: "N",
      exportWarehousesArticles: "N",
      exportArticles: "N",
      exportProvidersPrices: "N",
      exportProviders: "N",
      exportBankMovements: "N",
      updateCompanyInformation: "N",
      stripePortal: "N",
      salesKPI: "N",
      purchasesKPI: "N",
      expensesKPI: "N",
      incomesKPI: "N",
      salesGraphs: "N",
      purchasesGraphs: "N",
      expensesGraphs: "N",
      incomesGraphs: "N",
      articlesList: "N",
      clientsList: "N",
      providersList: "N",
      calculateCompanyTotalValue: "N",
      salesDate: "N",
      purchasesDate: "N",
      expensesDate: "N",
      incomesDate: "N",
      articlesListDate: "N",
      clientsListDate: "N",
      providersListDate: "N",
      allAnalytics: "N",
      exportSalesArticles: "N",
      getManualMovementsDate: "N",
      getCancellationsDate: "N",
      articlesListMoneyDate: "N",
      getPersonalSalesPurchases: "N",
      getCompanySalesArticlesDate: "N",
      useVirtualWarehouse: "N",
      saveNewCompanyName: "N",
      editCompanyName: "N",
      getCompanyNames: "N",
      getAssets: "N",
      saveAssets: "N",
      editAssets: "N",
      deleteAssets: "N",
      makePrediction: "N",
      getCompanyTransfersDate: "N",
    },
    productivity: {
      allCalendar: "N",
      getEvents: "N",
      saveEvent: "N",
      deleteEvents: "N",
      editEvent: "N",
    },
    purchase: {
      allArticles: "N",
      allWarehouses: "N",
      allProviders: "N",
      allPurchaseOrders: "N",
      saveWarehouse: "N",
      getWarehouse: "N",
      saveArticle: "N",
      getArticles: "N",
      getArticleCostInformation: "N",
      saveProvider: "N",
      getProviders: "N",
      saveProvidersPrices: "N",
      saveProviderArticleCost: "N",
      getTypesDelivery: "N",
      saveTypeDelivery: "N",
      getPaymentPeriods: "N",
      savePaymentPeriods: "N",
      getPurchaseOrders: "N",
      getRequestOrders: "N",
      savePurchaseOrder: "N",
      cancelPurchaseOrder: "N",
      setProviderPrice: "N",
      confirmRequestOrder: "N",
      printOrSendPurchaseOrder: "N",
      saveProviderCosts: "N",
      saveProviderDiscounts: "N",
      generateSmartPurchaseOrder: "N",
      saveArticlePresentation: "N",
      transferArticles: "N",
      addArticlesQuantity: "N",
      confirmTransaction: "N",
      cancelTransaction: "N",
      receiveMaterial: "N",
      confirmAllMaterialReception: "N",
      editArticle: "N",
      editArticlePresentation: "N",
      editWarehouse: "N",
      editProvider: "N",
      editRequestOrders: "N",
      deleteRequestOrders: "N",
      editWarehouseMinMax: "N",
      deleteProviderPrice: "N",
      editExpense: "N",
      authorizeExpense: "N",
      allPurchaseRevision: "N",
      transferArticlesPending: "N",
      addArticlesQuantityPending: "N",
      resetSalesInventory: "N",
    },
    sales: {
      allPricesLists: "N",
      allClients: "N",
      allArticlesPrices: "N",
      savePriceList: "N",
      getPriceLists: "N",
      getSecretPriceLists: "N",
      getPriceListsMargin: "N",
      updatePriceListArticles: "N",
      saveClient: "N",
      getClients: "N",
      getQuotes: "N",
      confirmQuote: "N",
      saveSale: "N",
      cancelSale: "N",
      getSales: "N",
      allSales: "N",
      getCommercialBusiness: "N",
      saveCommercialBusiness: "N",
      savePaymentType: "N",
      getDiscountConcepts: "N",
      saveDiscountConcepts: "N",
      saveClarification: "N",
      cancelClarification: "N",
      saveCreditNote: "N",
      cancelCreditNote: "N",
      editClient: "N",
      editCommercialBusiness: "N",
      editQuotes: "N",
      deleteQuotes: "N",
      allPos: "N",
      getPos: "N",
      savePos: "N",
      updatePos: "N",
      openPos: "N",
      closePos: "N",
      confirmPos: "N",
      getPosSale: "N",
      getPosCortes: "N",
      authorizeCorte: "N",
      noAuthorizeCorte: "N",
      addManualMovement: "N",
      getSecrets: "N",
      saveSecret: "N",
      editSecret: "N",
      deleteSecrets: "N",
      printOrSendSaleQuote: "N",
      getClientsUnlocks: "N",
      saveClientUnlock: "N",
      deleteClientsUnlocks: "N",
      getSaleMaterialDeliveries: "N",
      sendSaleMaterial: "N",
      saveMaterialDeliverySign: "N",
      confirmAllMaterialDelivery: "N",
      savePricesListCosts: "N",
      savePricesListDiscounts: "N",
      deleteMissingSale: "N",
      saveMissingSale: "N",
      savePosSale: "N",
      editPriceList: "N",
      dailyCancelSale: "N",
      editClientCredit: "N",
      editClientLists: "N",
      deletePriceList: "N",
      saveQuotePos: "N",
      deleteQuotePos: "N",
      confirmQuotePos: "N",
      allDriverMaterialDelivery: "N",
      confirmAllDriverMaterialDelivery: "N",
      saveDriverMaterialDeliverySign: "N",
      downloadDriverMaterialDelivery: "N",
      sendMaterialNegative: "N",
      openAgainPos: "N",
      editMaterialDeliverySale: "N",
      editPartialSale: "N",
      cancelProfessionalServicesSale: "N",
      editProfessionalServicesSale: "N",
      getProfesionalServicesSales: "N",
      saveProfesionalServicesSale: "N",
      getProfessionalService: "N",
      editProfessionalService: "N",
      saveProfessionalService: "N",
      allProfessionalServices: "N",
      removeSaleBackOrder: "N",
      editPartialSaleOrder: "N",
    },
    treasury: {
      allAccounts: "N",
      getExpenses: "N",
      addExpense: "N",
      authorizeExpense: "N",
      cancelExpense: "N",
      addPayment: "N",
      useAdvancedPayment: "N",
      cancelPayment: "N",
      getPayments: "N",
      addIncome: "N",
      cancelIncome: "N",
      getIncomes: "N",
      addAdvancedPayment: "N",
      cancelAdvancedPayment: "N",
      getAdvancedPayments: "N",
      editExpense: "N",
      editAdvancedPayment: "N",
      addManualMovementExpense: "N",
      editExpenseBasicInformation: "N",
      addManualMovementIncome: "N",
      editIncomeBasicInformation: "N",
      removeMissingAccountsExpenses: "N",
      removeMissingAccountsIncomes: "N",
      removeMissingPaymentsProviders: "N",
      removeMissingPaymentsClients: "N",
      generateClientBalanceReport: "N",
      addClientManualBalanceEntry: "N",
      editClientCredit: "N",
    },
    accounting: {
      allAccounting: "N",
      allSatDocument: "N",
      connectSatAccount: "N",
      synchDocument: "N",
      getDocuments: "N",
      getDocumentsXML: "N",
      saveInvoice: "N",
      getInvoicePDF: "N",
      getInvoiceXML: "N",
      getAcuse: "N",
      saveInvoiceCreditNote: "N",
      getCreditNotePDF: "N",
      getCreditNoteXML: "N",
      getAcuseCreditNote: "N",
      saveInvoicePayment: "N",
      getPaymentPDF: "N",
      getPaymentXML: "N",
      getAcusePayment: "N",
      savePorteCard: "N",
      getPorteCardPDF: "N",
      getPorteCardXML: "N",
      cancelPorteCard: "N",
      getAcusePorteCard: "N",
      saveInvoiceProfessionalServicesSale: "N",
      getProfessionalServicesInvoicePDF: "N",
      getProfessionalServicesCancelInvoicePDF: "N",
      allAccountingReports: "N",
      getClientsBalancesInvoices: "N",
      getClientsAccountsInvoices: "N",
      getSalesNoPayments: "N",
      getClientsAccountsPayments: "N",
      getProvidersBalanceInvoices: "N",
      getProvidersAccountsDetails: "N",
      getProvidersAccountsPayments: "N",
      getCashSales: "N",
      getSalesXMLDifferences: "N",
      getDailyInvoices: "N",
      getAccontableAccounts: "N",
      saveAccontableAccounts: "N",
    },
    humanResources: {
      allPersonal: "N",
      getPersonal: "N",
      savePersonal: "N",
      deletePersonal: "N",
      getOrganizationBoard: "N",
      editOrganizationBoard: "N",
      editPersonal: "N",
      seeComissionsInformation: "N",
      getBonds: "N",
      saveBond: "N",
      editBond: "N",
      disableBond: "N",
      activateBond: "N",
      deleteBond: "N",
      getComissions: "S",
      getAllComissions: "N",
      getPersonalComissions: "N",
      addIncidence: "N",
      getPersonalInventory: "N",
      savePersonalInventroy: "N",
      editPersonalInventory: "N",
      deletePersonalInventory: "N",
      getPersonalLoans: "N",
      savePersonalLoan: "N",
      savePersonalLoanPayment: "N",
      changePersonalLoanStatus: "N",
      savePersonalExtraHours: "N",
      getPersonalExtraHours: "N",
      deletePersonalExtraHours: "N",
      getPersonalMissingHours: "N",
      savePersonalMissingHours: "N",
      deletePersonalMissingHours: "N",
      getPersonalVacations: "N",
      savePersonalVacations: "N",
      deletePersonalVacations: "N",
    },
  },
};

export { initialPermissions };
