var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[(!_vm.companyNameInformation)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar razón social extra ")]):_vm._e(),(!_vm.companyNameInformation)?_c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado.")]),_vm._v(" Una vez registrada la razón social extra, podrás usarla para timbrar ventas ")]):_vm._e(),_c('div',{staticClass:"control add-global-controls special-margin"},[_c('b-field',{staticClass:"file is-primary upload-button"},[_c('b-upload',{staticClass:"file-label",attrs:{"accept":".cer"},on:{"input":function (value) { return _vm.transformCertificate(value); }}},[_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-label"},[_vm._v(" Subir certificado SAT")])])])],1),_c('b-field',{staticClass:"file is-primary upload-button"},[_c('b-upload',{staticClass:"file-label",attrs:{"accept":".key"},on:{"input":function (value) { return _vm.transformKey(value); }}},[_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-label"},[_vm._v(" Subir llave privada SAT")])])])],1)],1),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCompanyNameRequiredErrors.NOMBRE_FAC },"message":{
        'La razón social sin régimen no es válida':
          _vm.newCompanyNameRequiredErrors.NOMBRE_FAC,
      }}},[_c('template',{slot:"label"},[_vm._v(" Razón social sin tipo de sociedad (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"Si su compañía es 'Mi compañía S.A. de C.V.' poner solo 'Mi compañía'"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-input',{attrs:{"placeholder":"Ejemplo: Compañía","expanded":"","icon":"file-outline","required":""},on:{"input":function (val) { return (_vm.newCompanyName.NOMBRE_FAC =
              _vm.newCompanyName.NOMBRE_FAC.toUpperCase()); }},model:{value:(_vm.newCompanyName.NOMBRE_FAC),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "NOMBRE_FAC", $$v)},expression:"newCompanyName.NOMBRE_FAC"}})],2)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"RFC de la compañía (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: XAXX010101000","expanded":"","icon":"file-outline","required":""},model:{value:(_vm.newCompanyName.RFC),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "RFC", $$v)},expression:"newCompanyName.RFC"}})],1),_c('b-field',{attrs:{"label":"Régimen fiscal (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCompanyNameRequiredErrors.CLAVE_REGIMEN },"message":{
        'El régimen fical no es valido':
          _vm.newCompanyNameRequiredErrors.CLAVE_REGIMEN,
      }}},[_c('b-select',{staticClass:"required-input",attrs:{"placeholder":"Ejemplo: Régimen fiscal","icon":"briefcase-outline","expanded":"","required":""},model:{value:(_vm.newCompanyName.CLAVE_REGIMEN),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "CLAVE_REGIMEN", $$v)},expression:"newCompanyName.CLAVE_REGIMEN"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione un régimen")]),_vm._l((_vm.regimenes),function(singleRegimen){return _c('option',{key:singleRegimen._id,domProps:{"value":singleRegimen._id}},[_vm._v(" "+_vm._s(singleRegimen.DESCRIPCION)+" ")])})],2)],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCompanyNameRequiredErrors.CERTIFICADO_SAT },"message":{
        'El certificado SAT en base 64 no es válido':
          _vm.newCompanyNameRequiredErrors.CERTIFICADO_SAT,
      }}},[_c('template',{slot:"label"},[_vm._v(" Certificado SAT en base 64 (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"Si no cuenta con su certificado SAT en base 64 puede subir el archivo .cer en el botón 'Subir certificado SAT' y nosotros transformaremos el certificado a base 64"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-input',{attrs:{"placeholder":"Ejemplo: MIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQw...","expanded":"","icon":"directions-fork","password-reveal":"","type":"password","required":""},model:{value:(_vm.newCompanyName.CERTIFICADO_SAT),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "CERTIFICADO_SAT", $$v)},expression:"newCompanyName.CERTIFICADO_SAT"}})],2),_c('b-field',{attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCompanyNameRequiredErrors.LLAVE_SAT },"message":{
        'La llave SAT en base 64 no es válida':
          _vm.newCompanyNameRequiredErrors.LLAVE_SAT,
      }}},[_c('template',{slot:"label"},[_vm._v(" Llave SAT en base 64 (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"Si no cuenta con su llave privada SAT en base 64 puede subir el archivo .key en el botón 'Subir llave privada SAT' y nosotros transformaremos la llave a base 64"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-input',{attrs:{"placeholder":"Ejemplo: MIIFuzCCA6OgAwIBAgIUMzAwMDEwMDAwMDA0MDAwMDI...","expanded":"","icon":"directions-fork","password-reveal":"","type":"password","required":""},model:{value:(_vm.newCompanyName.LLAVE_SAT),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "LLAVE_SAT", $$v)},expression:"newCompanyName.LLAVE_SAT"}})],2)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Clave de llave privada SAT (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCompanyNameRequiredErrors.CLAVE_LLAVE },"message":{
        'La clave de llave privada SAT no es válida':
          _vm.newCompanyNameRequiredErrors.CLAVE_LLAVE,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 1234ABC","expanded":"","icon":"file-outline","password-reveal":"","type":"password","required":""},model:{value:(_vm.newCompanyName.CLAVE_LLAVE),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "CLAVE_LLAVE", $$v)},expression:"newCompanyName.CLAVE_LLAVE"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCompanyNameRequiredErrors.CP },"message":{
        'El código postal no es válido': _vm.newCompanyNameRequiredErrors.CP,
      }}},[_c('template',{slot:"label"},[_vm._v(" Código Postal (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.masks.customCP),expression:"masks.customCP"}],attrs:{"placeholder":"Ejemplo: 00000","expanded":"","icon":"directions-fork","type":"number","step":"any","required":""},on:{"input":function (value) { return _vm.searchForDirectionInformation(value); }},model:{value:(_vm.newCompanyName.CP),callback:function ($$v) {_vm.$set(_vm.newCompanyName, "CP", $$v)},expression:"newCompanyName.CP"}})],2)],1),_c('div',{staticClass:"add-global-controls"},[(!_vm.companyNameInformation)?_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveCompanyName(0)}}},[_vm._v(" Añadir razón social extra ")]):_vm._e(),(_vm.companyNameInformation)?_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveCompanyName(1)}}},[_vm._v(" Actualizar razón social extra ")]):_vm._e(),(_vm.companyNameInformation && _vm.companyNameInformation.STATUS === 'PE')?_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.activateCompanyName()}}},[_vm._v(" Activar razón social extra ")]):_vm._e(),(_vm.companyNameInformation && _vm.companyNameInformation.STATUS !== 'PE')?_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.disableCompanyName()}}},[_vm._v(" Desactivar razón social extra ")]):_vm._e(),(!_vm.companyNameInformation)?_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Regresar ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }