<template>
  <div :class="getCardClass">
    <p class="information-card-name">{{ name }}</p>
    <p
      v-if="format !== 'money' && format !== 'bold' && format !== 'moneyOnly'"
      class="information-card-value"
    >
      {{ value }}
    </p>
    <p v-if="format === 'bold'" class="information-card-value">
      <strong>{{ value }}</strong>
    </p>
    <p
      v-if="format === 'money' || format === 'moneyOnly'"
      class="information-card-value"
    >
      {{ value | money("MXN", 2) }}
    </p>
    <b-progress
      :rounded="false"
      type="is-success"
      size="is-medium"
      :value="value"
      :max="max"
      show-value
      format="raw"
      :precision="2"
      :keep-trailing-zeroes="true"
      locale="es-MX"
      v-if="format === 'money'"
    >
      {{ value | money("MXN", 2) }}
    </b-progress>

    <div v-if="moreDetails" class="more-details-container">
      <p
        class="single-more-detail"
        v-if="firstDetailMessage && !firstDetailMoney"
      >
        {{ firstDetailMessage }}: <b>{{ firstDetailValue }}</b>
      </p>
      <p
        class="single-more-detail"
        v-if="firstDetailMessage && firstDetailMoney"
      >
        {{ firstDetailMessage }}:
        <b>{{ firstDetailValue | money("MXN", 2) }}</b>
      </p>
      <p class="single-more-detail" v-if="secondDetailMessage">
        {{ secondDetailMessage }}:
        <b>{{ secondDetailValue | money("MXN", 2) }}</b>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationCard",
  components: {},
  props: [
    "name",
    "value",
    "format",
    "max",
    "width",
    "moreDetails",
    "firstDetailMoney",
    "firstDetailMessage",
    "firstDetailValue",
    "secondDetailMessage",
    "secondDetailValue",
  ],
  data() {
    return {};
  },
  methods: {},
  computed: {
    /**
     *
     * @desc returns the class of the cards depending on the prop width used
     */
    getCardClass() {
      if (this.width === "half") {
        return "global-border-radius global-border-shadow information-card-container-half-width";
      } else if (this.width === "half-centerd") {
        return "global-border-radius global-border-shadow information-card-container-half-width-centered";
      } else if (this.width === "full") {
        return "global-border-radius global-border-shadow information-card-container-full-width";
      } else if (this.width === "three") {
        return "global-border-radius global-border-shadow information-card-container-three-width";
      } else if (this.width === "four") {
        return "global-border-radius global-border-shadow information-card-container-four-width";
      } else if (this.width === "four-centerd") {
        return "global-border-radius global-border-shadow information-card-container-four-width-centered";
      } else if (this.width === "three-centerd") {
        return "global-border-radius global-border-shadow information-card-container-three-width-centered";
      } else if (this.width === "full-centered") {
        return "global-border-radius global-border-shadow information-card-container-full-width-centered";
      }
      return " global-border-radius global-border-shadow information-card-container-half-width";
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Global/style/InformationCards.css";
</style>
