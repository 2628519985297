/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="addUser()"
          >Agregar nuevo usuario</b-button
        >
      </div>
      <div class="control">
        <b-button type="is-primary" @click="deleteUser(checkedUsers)"
          >Borrar usuarios seleccionados</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de usuarios: <b>{{ users.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="users"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Users"
        checkable
        :checked-rows.sync="checkedUsers"
        :is-row-checkable="
          (row) => {
            return row.name !== 'admin';
          }
        "
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.name}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="name"
      >
        <template #empty>
          <div class="has-text-centered">No hay usuarios</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <AddUserModal :userInformation="props.row" />
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddUserModal from "./AddUserModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "UsersList",
  components: {
    AddUserModal,
  },
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      checkedUsers: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "email",
          label: "Correo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "permissionsName",
          label: "Perfil de permisos",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    addUser() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddUserModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async deleteUser(users) {
      try {
        if (users.length > 0) {
          let response = await this.$store.dispatch("DELETEUSERS", users);
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se borraron exitosamente los usuarios seleccionados",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al borrar los usuarios seleccionados",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar los usuarios seleccionados, seleccione uno o más usuarios para borrar",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al borrar los usuarios seleccionados",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    users() {
      return this.$store.getters.USERS_ALL.map((singleUser) => {
        const userWithProps = { ...singleUser };
        userWithProps.permissionsName = userWithProps.permissions.name;
        if (userWithProps.STATUS === "AC") {
          userWithProps.STATUS_FOR = "Activo";
        }
        if (userWithProps.STATUS === "PE") {
          userWithProps.STATUS_FOR = "Desactivado";
        }
        return userWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
