/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <h1 class="add-global-modal-main-title">Seleccinar perfil de permisos</h1>
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="table-header">
      Cantidad de perfiles de permisos: <b>{{ permissions.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="permissions"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Permissions"
        :checked-rows.sync="checkedPermissions"
        :is-row-checkable="
          (row) => {
            return row.name !== 'Admin' && row.numberOfUsers === 0;
          }
        "
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.name}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="name"
        @click="(row) => selectPermission(row)"
      >
        <template #empty>
          <div class="has-text-centered">No hay perfiles de permisos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <p>No hay información que mostrar</p>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddPermissionsModal from "./AddPermissionsModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { EventBus } from "../../../event-bus";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "SelectPermissions",
  components: {},
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      checkedPermissions: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      columns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "description",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "numberOfUsers",
          label: "Número de usuarios",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    selectPermission(permission) {
      this.closeModal();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPermissionsModal,
        props: {
          permissionsToClone: permission,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        return userWithProps;
      });
    },
    permissions() {
      return this.$store.getters.PERMISSIONS.map((singlePermissions) => {
        const permissionsWithProps = { ...singlePermissions };
        let numberOfUsers = 0;
        for (const singleUser of this.users) {
          if (singleUser.permissions._id === permissionsWithProps._id) {
            numberOfUsers += 1;
          }
        }
        permissionsWithProps.numberOfUsers = numberOfUsers;
        return permissionsWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
