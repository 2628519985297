/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!permissionsInformation">
      Agregar nuevo perfil de permisos
    </h1>

    <div v-if="!permissionsInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Los permisos no
      incluidos dentro del plan no pueden ser activados.
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': newPermissionsErrors.name }"
        :message="{
          'El nombre no es valido': newPermissionsErrors.name,
        }"
      >
        <b-input
          placeholder="Ejemplo: Ventas"
          expanded
          icon="card-account-details-outline"
          required
          :disabled="newPermissions.name.toLowerCase() === 'master'"
          v-model="newPermissions.name"
        />
      </b-field>
      <b-field label="Descripción (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Descripción"
          expanded
          icon="clipboard"
          v-model="newPermissions.description"
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Permisos</h3>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="store" label="General"></b-menu-item>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.calculateCompanyTotalValue"
        :disabled="
          companyPaymentPlanModules.main.calculateCompanyTotalValue === 'N'
        "
      >
        Ver valor total de la compañía
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.updateCompanyInformation"
        :disabled="
          companyPaymentPlanModules.main.updateCompanyInformation === 'N'
        "
      >
        Actualizar información de la compañía
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getCompanyNames"
        :disabled="companyPaymentPlanModules.main.getCompanyNames === 'N'"
      >
        Obtener razones sociales extras
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.editCompanyName"
        :disabled="companyPaymentPlanModules.main.editCompanyName === 'N'"
      >
        Actualizar razones sociales extras
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.saveNewCompanyName"
        :disabled="companyPaymentPlanModules.main.saveNewCompanyName === 'N'"
      >
        Añadir razones sociales extras
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getAssets"
        :disabled="companyPaymentPlanModules.main.getAssets === 'N'"
      >
        Obtener activos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.saveAssets"
        :disabled="companyPaymentPlanModules.main.saveAssets === 'N'"
      >
        Añadir activos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.editAssets"
        :disabled="companyPaymentPlanModules.main.editAssets === 'N'"
      >
        Actualizar activos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.deleteAssets"
        :disabled="companyPaymentPlanModules.main.deleteAssets === 'N'"
      >
        Borrar activos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.makePrediction"
        :disabled="companyPaymentPlanModules.main.makePrediction === 'N'"
      >
        Generar predicciones de artículos
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="arrow-down" label="Importación"></b-menu-item>
      </div>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importArticles"
        :disabled="companyPaymentPlanModules.main.importArticles === 'N'"
      >
        Importar artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importPresentations"
        :disabled="companyPaymentPlanModules.main.importPresentations === 'N'"
      >
        Importar presentaciones
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importWarehousesArticles"
        :disabled="
          companyPaymentPlanModules.main.importWarehousesArticles === 'N'
        "
      >
        Importar inventario
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importProviders"
        :disabled="companyPaymentPlanModules.main.importProviders === 'N'"
      >
        Importar proveedores
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importBankMovements"
        :disabled="companyPaymentPlanModules.main.importBankMovements === 'N'"
      >
        Importar movimientos bancarios
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importProvidersPrices"
        :disabled="companyPaymentPlanModules.main.importProvidersPrices === 'N'"
      >
        Importar precios de proveedores
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importPricesLists"
        :disabled="companyPaymentPlanModules.main.importPricesLists === 'N'"
      >
        Importar listas de precios
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importClients"
        :disabled="companyPaymentPlanModules.main.importClients === 'N'"
      >
        Importar clientes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.importPricesListsArticles"
        :disabled="
          companyPaymentPlanModules.main.importPricesListsArticles === 'N'
        "
      >
        Importar precios de articulos
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="arrow-up" label="Exportación"></b-menu-item>
      </div>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportArticles"
        :disabled="companyPaymentPlanModules.main.exportArticles === 'N'"
      >
        Exportar artículos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportWarehousesArticles"
        :disabled="
          companyPaymentPlanModules.main.exportWarehousesArticles === 'N'
        "
      >
        Exportar inventario
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportProviders"
        :disabled="companyPaymentPlanModules.main.exportProviders === 'N'"
      >
        Exportar proveedores
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportProvidersPrices"
        :disabled="companyPaymentPlanModules.main.exportProvidersPrices === 'N'"
      >
        Exportar precios de proveedores
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportPricesLists"
        :disabled="companyPaymentPlanModules.main.exportPricesLists === 'N'"
      >
        Exportar listas de precios
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportClients"
        :disabled="companyPaymentPlanModules.main.exportClients === 'N'"
      >
        Exportar clientes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportPricesListsArticles"
        :disabled="
          companyPaymentPlanModules.main.exportPricesListsArticles === 'N'
        "
      >
        Exportar precios de articulos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.exportSalesArticles"
        :disabled="companyPaymentPlanModules.main.exportSalesArticles === 'N'"
      >
        Exportar artículos en ventas
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="chart-bar" label="Analisis y reportes"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          v-model="newPermissions.permissions.main.allAnalytics"
          :disabled="companyPaymentPlanModules.main.allAnalytics === 'N'"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.salesDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.salesDate === 'N'
        "
      >
        Obtener total de ventas y cancelaciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.clientsListDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.clientsListDate === 'N'
        "
      >
        Obtener total de ventas por cliente
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getManualMovementsDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.getManualMovementsDate === 'N'
        "
      >
        Obtener movimientos manuales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getCancellationsDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.getCancellationsDate === 'N'
        "
      >
        Obtener cancelaciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.purchasesDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.purchasesDate === 'N'
        "
      >
        Obtener total de compras y cancelaciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.providersListDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.providersListDate === 'N'
        "
      >
        Obtener total de compras por proveedor
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.expensesDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.expensesDate === 'N'
        "
      >
        Obtener total de cuentas por pagar
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.incomesDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.incomesDate === 'N'
        "
      >
        Obtener total de cuentas por cobrar
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.articlesListDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.articlesListDate === 'N'
        "
      >
        Obtener artículos más comprados y vendidos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.articlesListMoneyDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.articlesListMoneyDate === 'N'
        "
      >
        Obtener artículos más vendidos por volumen
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getPersonalSalesPurchases"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.getPersonalSalesPurchases === 'N'
        "
      >
        Obtener ventas y compras de personal y gerentes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getCompanySalesArticlesDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.getCompanySalesArticlesDate === 'N'
        "
      >
        Obtener artículos en ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.main.getCompanyTransfersDate"
        :disabled="
          newPermissions.permissions.main.allAnalytics === 'N' ||
          companyPaymentPlanModules.main.getCompanyTransfersDate === 'N'
        "
      >
        Obtener atransferencias
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="hammer-screwdriver" label="Artículos"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          v-model="newPermissions.permissions.purchase.allArticles"
          :disabled="companyPaymentPlanModules.purchase.allArticles === 'N'"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.purchase.getArticles === 'N'
        "
        v-model="newPermissions.permissions.purchase.getArticles"
      >
        Obtener artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.purchase.getArticleCostInformation === 'N'
        "
        v-model="newPermissions.permissions.purchase.getArticleCostInformation"
      >
        Ver información de costo de artículos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.purchase.saveArticle === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveArticle"
      >
        Añadir artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.purchase.editArticle === 'N'
        "
        v-model="newPermissions.permissions.purchase.editArticle"
      >
        Actualizar artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.purchase.saveArticlePresentation === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveArticlePresentation"
      >
        Añadir presentaciones de artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.purchase.editArticlePresentation === 'N'
        "
        v-model="newPermissions.permissions.purchase.editArticlePresentation"
      >
        Actualizar presentaciones de artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.getBrands === 'N'
        "
        v-model="newPermissions.permissions.global.getBrands"
      >
        Ver marcas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.saveBrands === 'N'
        "
        v-model="newPermissions.permissions.global.saveBrands"
      >
        Añadir marcas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.editBrands === 'N'
        "
        v-model="newPermissions.permissions.global.editBrands"
      >
        Actualizar marcas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.global.getMainCategories"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.getMainCategories === 'N'
        "
      >
        Obtener familias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.global.saveMainCategory"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.saveMainCategory === 'N'
        "
      >
        Añadir familias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.global.editMainCategories"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.editMainCategories === 'N'
        "
      >
        Actualizar familias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.global.getSCategories"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.getSCategories === 'N'
        "
      >
        Obtener subfamilias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.global.saveSCategory"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.saveSCategory === 'N'
        "
      >
        Añadir subfamilias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        v-model="newPermissions.permissions.global.editSCategories"
        :disabled="
          newPermissions.permissions.purchase.allArticles === 'N' ||
          companyPaymentPlanModules.global.editSCategories === 'N'
        "
      >
        Actualizar subfamilias
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="warehouse" label="Almacenes"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.purchase.allWarehouses === 'N'"
          v-model="newPermissions.permissions.purchase.allWarehouses"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.getWarehouse === 'N'
        "
        v-model="newPermissions.permissions.purchase.getWarehouse"
      >
        Obtener almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.saveWarehouse === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveWarehouse"
      >
        Añadir almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.editWarehouse === 'N'
        "
        v-model="newPermissions.permissions.purchase.editWarehouse"
      >
        Actualizar almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.addArticlesQuantity === 'N'
        "
        v-model="newPermissions.permissions.purchase.addArticlesQuantity"
      >
        Añadir inventario a almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.addArticlesQuantityPending === 'N'
        "
        v-model="newPermissions.permissions.purchase.addArticlesQuantityPending"
      >
        Añadir inventario a almacenes transferencia pendiente
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.editWarehouseMinMax === 'N'
        "
        v-model="newPermissions.permissions.purchase.editWarehouseMinMax"
      >
        Editar inventarios mínimos y máximos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.transferArticles === 'N'
        "
        v-model="newPermissions.permissions.purchase.transferArticles"
      >
        Transferir inventario entre almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.transferArticlesPending === 'N'
        "
        v-model="newPermissions.permissions.purchase.transferArticlesPending"
      >
        Transferir inventario entre almacenes transferencia pendiente
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.global.sendMaterialMovement === 'N'
        "
        v-model="newPermissions.permissions.global.sendMaterialMovement"
      >
        Enviar movimientos de inventarios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.confirmTransaction === 'N'
        "
        v-model="newPermissions.permissions.purchase.confirmTransaction"
      >
        Confirmar transferencias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.cancelTransaction === 'N'
        "
        v-model="newPermissions.permissions.purchase.cancelTransaction"
      >
        Cancelar transferencias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        disabled
        v-model="newPermissions.permissions.main.useVirtualWarehouse"
      >
        Usar almacen virtual
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allWarehouses === 'N' ||
          companyPaymentPlanModules.purchase.resetSalesInventory === 'N'
        "
        v-model="newPermissions.permissions.purchase.resetSalesInventory"
      >
        Reiniciar inventario de ventas
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="recycle-variant"
          label="Transformación de material"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.global.allTransformations === 'N'
          "
          v-model="newPermissions.permissions.global.allTransformations"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.getFormulas === 'N'
        "
        v-model="newPermissions.permissions.global.getFormulas"
      >
        Obtener formulas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.saveFormula === 'N'
        "
        v-model="newPermissions.permissions.global.saveFormula"
      >
        Añadir formulas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.editFormula === 'N'
        "
        v-model="newPermissions.permissions.global.editFormula"
      >
        Actualizar formulas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.deleteFormula === 'N'
        "
        v-model="newPermissions.permissions.global.deleteFormula"
      >
        Borrar formulas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.getArticleTransformation === 'N'
        "
        v-model="newPermissions.permissions.global.getArticleTransformation"
      >
        Obtener transformaciones de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.saveArticleTransformation === 'N'
        "
        v-model="newPermissions.permissions.global.saveArticleTransformation"
      >
        Añadir transformaciones de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allTransformations === 'N' ||
          companyPaymentPlanModules.global.cancelArticleTransformation === 'N'
        "
        v-model="newPermissions.permissions.global.cancelArticleTransformation"
      >
        Cancelar transformaciones de material
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="truck" label="Vehiculos"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.global.allVehicules === 'N'"
          v-model="newPermissions.permissions.global.allVehicules"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allVehicules === 'N' ||
          companyPaymentPlanModules.global.getVehicules === 'N'
        "
        v-model="newPermissions.permissions.global.getVehicules"
      >
        Obtener vehiculos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allVehicules === 'N' ||
          companyPaymentPlanModules.global.saveVehicule === 'N'
        "
        v-model="newPermissions.permissions.global.saveVehicule"
      >
        Añadir vehiculos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allVehicules === 'N' ||
          companyPaymentPlanModules.global.editVehicules === 'N'
        "
        v-model="newPermissions.permissions.global.editVehicules"
      >
        Actualizar vehiculos
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="truck-delivery"
          label="Envios de material choferes"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.sales.allDriverMaterialDelivery === 'N'
          "
          v-model="newPermissions.permissions.sales.allDriverMaterialDelivery"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allDriverMaterialDelivery === 'N' ||
          companyPaymentPlanModules.sales.confirmAllDriverMaterialDelivery ===
            'N'
        "
        v-model="
          newPermissions.permissions.sales.confirmAllDriverMaterialDelivery
        "
      >
        Cambiar el estatus de envios de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allDriverMaterialDelivery === 'N' ||
          companyPaymentPlanModules.sales.saveDriverMaterialDeliverySign === 'N'
        "
        v-model="
          newPermissions.permissions.sales.saveDriverMaterialDeliverySign
        "
      >
        Actualizar envios de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allDriverMaterialDelivery === 'N' ||
          companyPaymentPlanModules.sales.downloadDriverMaterialDelivery === 'N'
        "
        v-model="
          newPermissions.permissions.sales.downloadDriverMaterialDelivery
        "
      >
        Descargar envios de material
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="account-group-outline"
          label="Proveedores"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.purchase.allProviders === 'N'"
          v-model="newPermissions.permissions.purchase.allProviders"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.getProviders === 'N'
        "
        v-model="newPermissions.permissions.purchase.getProviders"
      >
        Obtener proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.saveProvider === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveProvider"
      >
        Añadir proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.editProvider === 'N'
        "
        v-model="newPermissions.permissions.purchase.editProvider"
      >
        Actualizar proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.saveProvidersPrices === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveProvidersPrices"
      >
        Añadir precios de proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.saveProviderArticleCost === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveProviderArticleCost"
      >
        Seleccionar precios de proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.saveProviderCosts === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveProviderCosts"
      >
        Añadir indirectos de proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.saveProviderDiscounts === 'N'
        "
        v-model="newPermissions.permissions.purchase.saveProviderDiscounts"
      >
        Añadir descuentos de proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allProviders === 'N' ||
          companyPaymentPlanModules.purchase.deleteProviderPrice === 'N'
        "
        v-model="newPermissions.permissions.purchase.deleteProviderPrice"
      >
        Borrar precios de proveedores
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="receipt" label="Ordenes de compra"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.purchase.allPurchaseOrders === 'N'
          "
          v-model="newPermissions.permissions.purchase.allPurchaseOrders"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.savePurchaseOrder === 'N'
        "
        v-model="newPermissions.permissions.purchase.savePurchaseOrder"
      >
        Añadir pedidos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.getRequestOrders === 'N'
        "
        v-model="newPermissions.permissions.purchase.getRequestOrders"
      >
        Obtener pedidos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.editRequestOrders === 'N'
        "
        v-model="newPermissions.permissions.purchase.editRequestOrders"
      >
        Actualizar pedidos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.deleteRequestOrders === 'N'
        "
        v-model="newPermissions.permissions.purchase.deleteRequestOrders"
      >
        Borrar pedidos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.confirmRequestOrder === 'N'
        "
        v-model="newPermissions.permissions.purchase.confirmRequestOrder"
      >
        Confirmar pedidos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.getPurchaseOrders === 'N'
        "
        v-model="newPermissions.permissions.purchase.getPurchaseOrders"
      >
        Obtener ordenes de compra
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.cancelPurchaseOrder === 'N'
        "
        v-model="newPermissions.permissions.purchase.cancelPurchaseOrder"
      >
        Cancelar ordenes de compra
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.printOrSendPurchaseOrder === 'N'
        "
        v-model="newPermissions.permissions.purchase.printOrSendPurchaseOrder"
      >
        Imprimir o enviar orden de compra
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.receiveMaterial === 'N'
        "
        v-model="newPermissions.permissions.purchase.receiveMaterial"
      >
        Recibir material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.confirmAllMaterialReception === 'N'
        "
        v-model="
          newPermissions.permissions.purchase.confirmAllMaterialReception
        "
      >
        Cambiar estatus de recepción de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.allPurchaseRevision === 'N'
        "
        v-model="newPermissions.permissions.purchase.allPurchaseRevision"
      >
        Ver revisión de facturas compras
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.editExpense === 'N'
        "
        v-model="newPermissions.permissions.purchase.editExpense"
      >
        Editar facturas de compras
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.purchase.allPurchaseOrders === 'N' ||
          companyPaymentPlanModules.purchase.authorizeExpense === 'N'
        "
        v-model="newPermissions.permissions.purchase.authorizeExpense"
      >
        Autorizar facturas de compras
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="currency-usd" label="Ventas"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.sales.allSales === 'N'"
          v-model="newPermissions.permissions.sales.allSales"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.getQuotes === 'N'
        "
        v-model="newPermissions.permissions.sales.getQuotes"
      >
        Obtener cotizaciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.saveSale === 'N'
        "
        v-model="newPermissions.permissions.sales.saveSale"
      >
        Añadir cotizaciones o ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.cancelSale === 'N'
        "
        v-model="newPermissions.permissions.sales.cancelSale"
      >
        Cancelar ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.dailyCancelSale === 'N'
        "
        v-model="newPermissions.permissions.sales.dailyCancelSale"
      >
        Cancelar ventas del día
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.printOrSendSaleQuote === 'N'
        "
        v-model="newPermissions.permissions.sales.printOrSendSaleQuote"
      >
        Imprimir o enviar venta o cotización
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.editQuotes === 'N'
        "
        v-model="newPermissions.permissions.sales.editQuotes"
      >
        Actualizar cotizaciones o ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.deleteQuotes === 'N'
        "
        v-model="newPermissions.permissions.sales.deleteQuotes"
      >
        Borrar cotizaciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.confirmQuote === 'N'
        "
        v-model="newPermissions.permissions.sales.confirmQuote"
      >
        Confirmar cotizaciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.getSales === 'N'
        "
        v-model="newPermissions.permissions.sales.getSales"
      >
        Obtener ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.savePaymentType === 'N'
        "
        v-model="newPermissions.permissions.sales.savePaymentType"
      >
        Añadir método de pago
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.getDiscountConcepts === 'N'
        "
        v-model="newPermissions.permissions.sales.getDiscountConcepts"
      >
        Obtener conceptos de descuento
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.saveDiscountConcepts === 'N'
        "
        v-model="newPermissions.permissions.sales.saveDiscountConcepts"
      >
        Añadir conceptos de descuento
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.saveClarification === 'N'
        "
        v-model="newPermissions.permissions.sales.saveClarification"
      >
        Añadir aclaraciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.cancelClarification === 'N'
        "
        v-model="newPermissions.permissions.sales.cancelClarification"
      >
        Cancelar aclaraciones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.saveCreditNote === 'N'
        "
        v-model="newPermissions.permissions.sales.saveCreditNote"
      >
        Añadir notas de crédito
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.cancelCreditNote === 'N'
        "
        v-model="newPermissions.permissions.sales.cancelCreditNote"
      >
        Cancelar notas de crédito
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.getSaleMaterialDeliveries === 'N'
        "
        v-model="newPermissions.permissions.sales.getSaleMaterialDeliveries"
      >
        Obtener envios de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.sendSaleMaterial === 'N'
        "
        v-model="newPermissions.permissions.sales.sendSaleMaterial"
      >
        Añadir envios de material
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.sendMaterialNegative === 'N'
        "
        v-model="newPermissions.permissions.sales.sendMaterialNegative"
      >
        Añadir envios de material negativos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.editMaterialDeliverySale === 'N'
        "
        v-model="newPermissions.permissions.sales.editMaterialDeliverySale"
      >
        Editar envios de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.global.sendMaterialDelivery === 'N'
        "
        v-model="newPermissions.permissions.global.sendMaterialDelivery"
      >
        Imprimir o enviar envios de material por correo
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.saveMaterialDeliverySign === 'N'
        "
        v-model="newPermissions.permissions.sales.saveMaterialDeliverySign"
      >
        Guardar firma de envios de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.confirmAllMaterialDelivery === 'N'
        "
        v-model="newPermissions.permissions.sales.confirmAllMaterialDelivery"
      >
        Editar estatus de envios de material
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.editPartialSaleOrder === 'N'
        "
        v-model="newPermissions.permissions.sales.editPartialSaleOrder"
      >
        Permitir uso parcial en ordenes de compra
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.editPartialSale === 'N'
        "
        v-model="newPermissions.permissions.sales.editPartialSale"
      >
        Permitir uso parcial en envios de material
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allSales === 'N' ||
          companyPaymentPlanModules.sales.removeSaleBackOrder === 'N'
        "
        v-model="newPermissions.permissions.sales.removeSaleBackOrder"
      >
        Cancelar material faltante de enviar
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="doctor"
          label="Servicios profesionales"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.sales.allProfessionalServices === 'N'
          "
          v-model="newPermissions.permissions.sales.allProfessionalServices"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.getProfessionalService === 'N'
        "
        v-model="newPermissions.permissions.sales.getProfessionalService"
      >
        Obtener servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.saveProfessionalService === 'N'
        "
        v-model="newPermissions.permissions.sales.saveProfessionalService"
      >
        Añadir servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.editProfessionalService === 'N'
        "
        v-model="newPermissions.permissions.sales.editProfessionalService"
      >
        Actualizar servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.getProfesionalServicesSales === 'N'
        "
        v-model="newPermissions.permissions.sales.getProfesionalServicesSales"
      >
        Obtener ventas de servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.saveProfesionalServicesSale === 'N'
        "
        v-model="newPermissions.permissions.sales.saveProfesionalServicesSale"
      >
        Añadir ventas de servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.editProfessionalServicesSale === 'N'
        "
        v-model="newPermissions.permissions.sales.editProfessionalServicesSale"
      >
        Actualizar ventas de servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.sales.cancelProfessionalServicesSale === 'N'
        "
        v-model="
          newPermissions.permissions.sales.cancelProfessionalServicesSale
        "
      >
        Cancelar ventas de servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allProfessionalServices === 'N' ||
          companyPaymentPlanModules.global.sendEmailProfessionalServicesSale ===
            'N'
        "
        v-model="
          newPermissions.permissions.global.sendEmailProfessionalServicesSale
        "
      >
        Imprimir o enviar ventas de servicios profesionales
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="account-multiple" label="Clientes"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.sales.allClients === 'N'"
          v-model="newPermissions.permissions.sales.allClients"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.getClients === 'N'
        "
        v-model="newPermissions.permissions.sales.getClients"
      >
        Obtener clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.saveClient === 'N'
        "
        v-model="newPermissions.permissions.sales.saveClient"
      >
        Añadir clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.editClient === 'N'
        "
        v-model="newPermissions.permissions.sales.editClient"
      >
        Actualizar clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.editClientCredit === 'N'
        "
        v-model="newPermissions.permissions.sales.editClientCredit"
      >
        Modificar información de crédito del cliente
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.editClientLists === 'N'
        "
        v-model="newPermissions.permissions.sales.editClientLists"
      >
        Modificar información de ventas del cliente
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.getCommercialBusiness === 'N'
        "
        v-model="newPermissions.permissions.sales.getCommercialBusiness"
      >
        Obtener giros comerciales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.saveCommercialBusiness === 'N'
        "
        v-model="newPermissions.permissions.sales.saveCommercialBusiness"
      >
        Añadir giros comerciales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.editCommercialBusiness === 'N'
        "
        v-model="newPermissions.permissions.sales.editCommercialBusiness"
      >
        Actualizar giros comerciales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.getClientsUnlocks === 'N'
        "
        v-model="newPermissions.permissions.sales.getClientsUnlocks"
      >
        Obtener debloqueos de clientes o almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.saveClientUnlock === 'N'
        "
        v-model="newPermissions.permissions.sales.saveClientUnlock"
      >
        Añadir debloqueos de clientes o almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.sales.deleteClientsUnlocks === 'N'
        "
        v-model="newPermissions.permissions.sales.deleteClientsUnlocks"
      >
        Borrar desbloqueos de clientes o almacenes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.treasury.generateClientBalanceReport === 'N'
        "
        v-model="
          newPermissions.permissions.treasury.generateClientBalanceReport
        "
      >
        Obtener estados de cuenta de clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.treasury.addClientManualBalanceEntry === 'N'
        "
        v-model="
          newPermissions.permissions.treasury.addClientManualBalanceEntry
        "
      >
        Agregar movimiento manual a estado de cuenta del cliente
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allClients === 'N' ||
          companyPaymentPlanModules.treasury.editClientCredit === 'N'
        "
        v-model="newPermissions.permissions.treasury.editClientCredit"
      >
        Editar crédito disponible del cliente
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="cash-100" label="Listas de precios"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.sales.allPricesLists === 'N'"
          v-model="newPermissions.permissions.sales.allPricesLists"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPricesLists === 'N' ||
          companyPaymentPlanModules.sales.getPriceLists === 'N'
        "
        v-model="newPermissions.permissions.sales.getPriceLists"
      >
        Obtener listas de precios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPricesLists === 'N' ||
          companyPaymentPlanModules.sales.savePriceList === 'N'
        "
        v-model="newPermissions.permissions.sales.savePriceList"
      >
        Añadir listas de precios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPricesLists === 'N' ||
          companyPaymentPlanModules.sales.editPriceList === 'N'
        "
        v-model="newPermissions.permissions.sales.editPriceList"
      >
        Actualizar listas de precios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPricesLists === 'N' ||
          companyPaymentPlanModules.sales.deletePriceList === 'N'
        "
        v-model="newPermissions.permissions.sales.deletePriceList"
      >
        Borrar listas de precios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPricesLists === 'N' ||
          companyPaymentPlanModules.sales.getPriceListsMargin === 'N'
        "
        v-model="newPermissions.permissions.sales.getPriceListsMargin"
      >
        Ver margenes de utilidad de listas de precios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPricesLists === 'N' ||
          companyPaymentPlanModules.sales.getSecretPriceLists === 'N'
        "
        v-model="newPermissions.permissions.sales.getSecretPriceLists"
      >
        Ver listas de precios secretas
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="calculator"
          label="Precios de artículos"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.sales.allArticlesPrices === 'N'"
          v-model="newPermissions.permissions.sales.allArticlesPrices"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allArticlesPrices === 'N' ||
          companyPaymentPlanModules.sales.updatePriceListArticles === 'N'
        "
        v-model="newPermissions.permissions.sales.updatePriceListArticles"
      >
        Actualizar precios de artículos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allArticlesPrices === 'N' ||
          companyPaymentPlanModules.sales.savePricesListDiscounts === 'N'
        "
        v-model="newPermissions.permissions.sales.savePricesListDiscounts"
      >
        Actualizar descuentos de precios de artíuclos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allArticlesPrices === 'N' ||
          companyPaymentPlanModules.sales.savePricesListCosts === 'N'
        "
        v-model="newPermissions.permissions.sales.savePricesListCosts"
      >
        Actualizar indirectos de precios de artíuclos
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="point-of-sale" label="Punto de venta"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.sales.allPos === 'N'"
          v-model="newPermissions.permissions.sales.allPos"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.getPos === 'N'
        "
        v-model="newPermissions.permissions.sales.getPos"
      >
        Obtener cajas
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.savePos === 'N'
        "
        v-model="newPermissions.permissions.sales.savePos"
      >
        Añadir cajas
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.updatePos === 'N'
        "
        v-model="newPermissions.permissions.sales.updatePos"
      >
        Actualizar cajas
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.openPos === 'N'
        "
        v-model="newPermissions.permissions.sales.openPos"
      >
        Abrir cajas
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.openAgainPos === 'N'
        "
        v-model="newPermissions.permissions.sales.openAgainPos"
      >
        Abrir nuevamente cortes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.getPosCortes === 'N'
        "
        v-model="newPermissions.permissions.sales.getPosCortes"
      >
        Obtener cortes de cajas
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.addManualMovement === 'N'
        "
        v-model="newPermissions.permissions.sales.addManualMovement"
      >
        Añadir movimientos manuales
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.closePos === 'N'
        "
        v-model="newPermissions.permissions.sales.closePos"
      >
        Cerrar cortes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.authorizeCorte === 'N'
        "
        v-model="newPermissions.permissions.sales.authorizeCorte"
      >
        Autorizar cortes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.noAuthorizeCorte === 'N'
        "
        v-model="newPermissions.permissions.sales.noAuthorizeCorte"
      >
        No autorizar cortes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.getPosSale === 'N'
        "
        v-model="newPermissions.permissions.sales.getPosSale"
      >
        Obtener ventas de cortes
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.getSecrets === 'N'
        "
        v-model="newPermissions.permissions.sales.getSecrets"
      >
        Obtener secretos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.saveSecret === 'N'
        "
        v-model="newPermissions.permissions.sales.saveSecret"
      >
        Añadir secretos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.editSecret === 'N'
        "
        v-model="newPermissions.permissions.sales.editSecret"
      >
        Actualizar secretos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.deleteSecrets === 'N'
        "
        v-model="newPermissions.permissions.sales.deleteSecrets"
      >
        Borrar secretos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.saveMissingSale === 'N'
        "
        v-model="newPermissions.permissions.sales.saveMissingSale"
      >
        Añadir tickets
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.savePosSale === 'N'
        "
        v-model="newPermissions.permissions.sales.savePosSale"
      >
        Pagar tickets
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.deleteMissingSale === 'N'
        "
        v-model="newPermissions.permissions.sales.deleteMissingSale"
      >
        Borrar tickets pendientes de pago
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.saveQuotePos === 'N'
        "
        v-model="newPermissions.permissions.sales.saveQuotePos"
      >
        Guardar cotizaciones
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.deleteQuotePos === 'N'
        "
        v-model="newPermissions.permissions.sales.deleteQuotePos"
      >
        Borrar cotizaciones
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.sales.allPos === 'N' ||
          companyPaymentPlanModules.sales.confirmQuotePos === 'N'
        "
        v-model="newPermissions.permissions.sales.confirmQuotePos"
      >
        Confirmar cotizaciones
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="chart-multiple"
          label="Reportes contables"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.accounting.allAccountingReports === 'N'
          "
          v-model="newPermissions.permissions.accounting.allAccountingReports"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getClientsBalancesInvoices ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getClientsBalancesInvoices
        "
      >
        Ver estados de cuenta clientes facturado
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getClientsAccountsInvoices ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getClientsAccountsInvoices
        "
      >
        Ver facturas clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getSalesNoPayments === 'N'
        "
        v-model="newPermissions.permissions.accounting.getSalesNoPayments"
      >
        Ver ventas con pago sin facturar
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getClientsAccountsPayments ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getClientsAccountsPayments
        "
      >
        Ver pagos a clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getClientsAccountsPayments ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getClientsAccountsPayments
        "
      >
        Ver pagos a clientes
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getProvidersBalanceInvoices
        "
      >
        Ver estados de cuenta acreedores y proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getProvidersAccountsDetails ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getProvidersAccountsDetails
        "
      >
        Ver detallles de cuentas acreedores y proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getProvidersAccountsPayments ===
            'N'
        "
        v-model="
          newPermissions.permissions.accounting.getProvidersAccountsPayments
        "
      >
        Ver pagos a acreedores y proveedores
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getCashSales === 'N'
        "
        v-model="newPermissions.permissions.accounting.getCashSales"
      >
        Ver facturas en efectivo en PV y venta en general
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccountingReports === 'N' ||
          companyPaymentPlanModules.accounting.getSalesXMLDifferences === 'N'
        "
        v-model="newPermissions.permissions.accounting.getSalesXMLDifferences"
      >
        Ver diferencia entre total venta y xml
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="file-outline" label="Contabilidad"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.accounting.allAccounting === 'N'"
          v-model="newPermissions.permissions.accounting.allAccounting"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.saveInvoice === 'N'
        "
        v-model="newPermissions.permissions.accounting.saveInvoice"
      >
        Timbrar facturas de ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getInvoiceXML === 'N'
        "
        v-model="newPermissions.permissions.accounting.getInvoiceXML"
      >
        Obtener XML de ventas o servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getInvoicePDF === 'N'
        "
        v-model="newPermissions.permissions.accounting.getInvoicePDF"
      >
        Descargar o enviar PDF de ventas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getAcuse === 'N'
        "
        v-model="newPermissions.permissions.accounting.getAcuse"
      >
        Obtener acuse de ventas o servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting
            .saveInvoiceProfessionalServicesSale === 'N'
        "
        v-model="
          newPermissions.permissions.accounting
            .saveInvoiceProfessionalServicesSale
        "
      >
        Timbrar facturas de servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting
            .getProfessionalServicesInvoicePDF === 'N'
        "
        v-model="
          newPermissions.permissions.accounting
            .getProfessionalServicesInvoicePDF
        "
      >
        Descargar o enviar PDF de servicios profesionales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.saveInvoiceCreditNote === 'N'
        "
        v-model="newPermissions.permissions.accounting.saveInvoiceCreditNote"
      >
        Timbrar notas de crédito
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getCreditNotePDF === 'N'
        "
        v-model="newPermissions.permissions.accounting.getCreditNotePDF"
      >
        Descargar o enviar PDF de notas de crédito
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getCreditNoteXML === 'N'
        "
        v-model="newPermissions.permissions.accounting.getCreditNoteXML"
      >
        Obtener XML de notas de crédito
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getAcuseCreditNote === 'N'
        "
        v-model="newPermissions.permissions.accounting.getAcuseCreditNote"
      >
        Obtener acuse de notas de crédito
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.saveInvoicePayment === 'N'
        "
        v-model="newPermissions.permissions.accounting.saveInvoicePayment"
      >
        Timbrar pagos y anticipos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getPaymentPDF === 'N'
        "
        v-model="newPermissions.permissions.accounting.getPaymentPDF"
      >
        Descargar o enviar PDF de pagos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getPaymentXML === 'N'
        "
        v-model="newPermissions.permissions.accounting.getPaymentXML"
      >
        Obtener XML de pagos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getAcusePayment === 'N'
        "
        v-model="newPermissions.permissions.accounting.getAcusePayment"
      >
        Obtener acuse de pagos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.savePorteCard === 'N'
        "
        v-model="newPermissions.permissions.accounting.savePorteCard"
      >
        Timbrar cartas porte
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getPorteCardPDF === 'N'
        "
        v-model="newPermissions.permissions.accounting.getPorteCardPDF"
      >
        Descargar o enviar cartas porte
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getPorteCardXML === 'N'
        "
        v-model="newPermissions.permissions.accounting.getPorteCardXML"
      >
        Obtener XML de cartas porte
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.cancelPorteCard === 'N'
        "
        v-model="newPermissions.permissions.accounting.cancelPorteCard"
      >
        Cancelar cartas porte
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getAcusePorteCard === 'N'
        "
        v-model="newPermissions.permissions.accounting.getAcusePorteCard"
      >
        Obtener acuse de cartas porte
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getDailyInvoices === 'N'
        "
        v-model="newPermissions.permissions.accounting.getDailyInvoices"
      >
        Obtener facturas globales
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.getAccontableAccounts === 'N'
        "
        v-model="newPermissions.permissions.accounting.getAccontableAccounts"
      >
        Obtener cuentas contables globales
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allAccounting === 'N' ||
          companyPaymentPlanModules.accounting.saveAccontableAccounts === 'N'
        "
        v-model="newPermissions.permissions.accounting.saveAccontableAccounts"
      >
        Añadir cuentas contables globales
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="account-cash-outline" label="Cuentas"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.treasury.allAccounts === 'N'"
          v-model="newPermissions.permissions.treasury.allAccounts"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.getExpenses === 'N'
        "
        v-model="newPermissions.permissions.treasury.getExpenses"
      >
        Obtener gastos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.addExpense === 'N'
        "
        v-model="newPermissions.permissions.treasury.addExpense"
      >
        Añadir gastos
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.editExpense === 'N'
        "
        v-model="newPermissions.permissions.treasury.editExpense"
      >
        Actualizar gastos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.authorizeExpense === 'N'
        "
        v-model="newPermissions.permissions.treasury.authorizeExpense"
      >
        Autorizar gastos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.editExpenseBasicInformation === 'N'
        "
        v-model="
          newPermissions.permissions.treasury.editExpenseBasicInformation
        "
      >
        Actualizar gastos autorizados
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.addManualMovementExpense === 'N'
        "
        v-model="newPermissions.permissions.treasury.addManualMovementExpense"
      >
        Añadir movimientos manuales a gastos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.cancelExpense === 'N'
        "
        v-model="newPermissions.permissions.treasury.cancelExpense"
      >
        Cancelar gastos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.removeMissingAccountsExpenses ===
            'N'
        "
        v-model="
          newPermissions.permissions.treasury.removeMissingAccountsExpenses
        "
      >
        Remover saldos menores de gastos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.getIncomes === 'N'
        "
        v-model="newPermissions.permissions.treasury.getIncomes"
      >
        Obtener ingresos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.editIncomeBasicInformation === 'N'
        "
        v-model="newPermissions.permissions.treasury.editIncomeBasicInformation"
      >
        Actualizar ingresos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.addManualMovementIncome === 'N'
        "
        v-model="newPermissions.permissions.treasury.addManualMovementIncome"
      >
        Añadir movimientos manuales a ingresos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.addIncome === 'N'
        "
        v-model="newPermissions.permissions.treasury.addIncome"
      >
        Añadir ingresos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.cancelIncome === 'N'
        "
        v-model="newPermissions.permissions.treasury.cancelIncome"
      >
        Cancelar ingresos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.removeMissingAccountsIncomes ===
            'N'
        "
        v-model="
          newPermissions.permissions.treasury.removeMissingAccountsIncomes
        "
      >
        Remover saldos menores de ingresos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.getPayments === 'N'
        "
        v-model="newPermissions.permissions.treasury.getPayments"
      >
        Obtener pagos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.getAdvancedPayments === 'N'
        "
        v-model="newPermissions.permissions.treasury.getAdvancedPayments"
      >
        Obtener anticipos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.addPayment === 'N'
        "
        v-model="newPermissions.permissions.treasury.addPayment"
      >
        Añadir pagos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.addAdvancedPayment === 'N'
        "
        v-model="newPermissions.permissions.treasury.addAdvancedPayment"
      >
        Añadir anticipos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.editAdvancedPayment === 'N'
        "
        v-model="newPermissions.permissions.treasury.editAdvancedPayment"
      >
        Editar anticipos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.useAdvancedPayment === 'N'
        "
        v-model="newPermissions.permissions.treasury.useAdvancedPayment"
      >
        Usar anticipos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.cancelPayment === 'N'
        "
        v-model="newPermissions.permissions.treasury.cancelPayment"
      >
        Cancelar pagos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.cancelAdvancedPayment === 'N'
        "
        v-model="newPermissions.permissions.treasury.cancelAdvancedPayment"
      >
        Cancelar anticipos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.removeMissingPaymentsProviders ===
            'N'
        "
        v-model="
          newPermissions.permissions.treasury.removeMissingPaymentsProviders
        "
      >
        Remover saldos menores de anticipos sin cuenta por pagar
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.treasury.allAccounts === 'N' ||
          companyPaymentPlanModules.treasury.removeMissingPaymentsClients ===
            'N'
        "
        v-model="
          newPermissions.permissions.treasury.removeMissingPaymentsClients
        "
      >
        Remover saldos menores de anticipos sin cuenta por cobrar
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item
          icon="align-vertical-center"
          label="Cuentas (Banco)"
        ></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.banks.allAccounts === 'N'"
          v-model="newPermissions.permissions.banks.allAccounts"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.getBanks === 'N'
        "
        v-model="newPermissions.permissions.banks.getBanks"
      >
        Obtener bancos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.saveBank === 'N'
        "
        v-model="newPermissions.permissions.banks.saveBank"
      >
        Añadir bancos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.editBanks === 'N'
        "
        v-model="newPermissions.permissions.banks.editBanks"
      >
        Actualizar bancos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.belvo.connecToBelvo === 'N'
        "
        v-model="newPermissions.permissions.belvo.connecToBelvo"
      >
        Conectar cuenta bancaria externa
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.saveBankAccounts === 'N'
        "
        v-model="newPermissions.permissions.banks.saveBankAccounts"
      >
        Añadir cuentas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.getBankAccounts === 'N'
        "
        v-model="newPermissions.permissions.banks.getBankAccounts"
      >
        Obtener cuentas
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.saveBankAccountsMovements === 'N'
        "
        v-model="newPermissions.permissions.banks.saveBankAccountsMovements"
      >
        Añadir movimientos bancarios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.synchBankAccountsMovements === 'N'
        "
        v-model="newPermissions.permissions.banks.synchBankAccountsMovements"
      >
        Sincronizar movimientos bancarios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.cancelMovement === 'N'
        "
        v-model="newPermissions.permissions.banks.cancelMovement"
      >
        Cancelar movimientos bancarios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.changeShowInAccounts === 'N'
        "
        v-model="newPermissions.permissions.banks.changeShowInAccounts"
      >
        Ocultar o mostrar movimientos bancarios en tesorería
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.synchBankAccountBalance === 'N'
        "
        v-model="newPermissions.permissions.banks.synchBankAccountBalance"
      >
        Sincronizar saldo de cuenta externa
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.banks.allAccounts === 'N' ||
          companyPaymentPlanModules.banks.consolidateMovements === 'N'
        "
        v-model="newPermissions.permissions.banks.consolidateMovements"
      >
        Consolidar saldo
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="file-document" label="Documentos SAT"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.accounting.allSatDocument === 'N' ||
            companyPaymentPlanModules.accounting.connectSatAccount === 'N'
          "
          v-model="newPermissions.permissions.accounting.allSatDocument"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allSatDocument === 'N' ||
          companyPaymentPlanModules.accounting.connectSatAccount === 'N'
        "
        v-model="newPermissions.permissions.accounting.connectSatAccount"
      >
        Conectar cuenta del SAT
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allSatDocument === 'N' ||
          companyPaymentPlanModules.accounting.synchDocument === 'N'
        "
        v-model="newPermissions.permissions.accounting.synchDocument"
      >
        Sincronizar documentos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allSatDocument === 'N' ||
          companyPaymentPlanModules.accounting.getDocuments === 'N'
        "
        v-model="newPermissions.permissions.accounting.getDocuments"
      >
        Obtener documentos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.accounting.allSatDocument === 'N' ||
          companyPaymentPlanModules.accounting.getDocumentsXML === 'N'
        "
        v-model="newPermissions.permissions.accounting.getDocumentsXML"
      >
        Obtener XML de documentos
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="calendar" label="Calendario"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.productivity.allCalendar === 'N'"
          v-model="newPermissions.permissions.productivity.allCalendar"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.productivity.allCalendar === 'N' ||
          companyPaymentPlanModules.productivity.getEvents === 'N'
        "
        v-model="newPermissions.permissions.productivity.getEvents"
      >
        Obtener eventos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.productivity.allCalendar === 'N' ||
          companyPaymentPlanModules.productivity.saveEvent === 'N'
        "
        v-model="newPermissions.permissions.productivity.saveEvent"
      >
        Añadir eventos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.productivity.allCalendar === 'N' ||
          companyPaymentPlanModules.productivity.editEvent === 'N'
        "
        v-model="newPermissions.permissions.productivity.editEvent"
      >
        Actualizar eventos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.productivity.allCalendar === 'N' ||
          companyPaymentPlanModules.productivity.deleteEvents === 'N'
        "
        v-model="newPermissions.permissions.productivity.deleteEvents"
      >
        Borrar eventos
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="account-group" label="Personal"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="
            companyPaymentPlanModules.humanResources.allPersonal === 'N'
          "
          v-model="newPermissions.permissions.humanResources.allPersonal"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonal === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getPersonal"
      >
        Obtener personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonal === 'N'
        "
        v-model="newPermissions.permissions.humanResources.savePersonal"
      >
        Añadir personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.seeComissionsInformation ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.seeComissionsInformation
        "
      >
        Ver información de comisiones de personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.editPersonal === 'N'
        "
        v-model="newPermissions.permissions.humanResources.editPersonal"
      >
        Actualizar personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.deletePersonal === 'N'
        "
        v-model="newPermissions.permissions.humanResources.deletePersonal"
      >
        Borrar personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getOrganizationBoard === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getOrganizationBoard"
      >
        Obtener organigrama
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.editOrganizationBoard === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.editOrganizationBoard
        "
      >
        Actualizar organigrama
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getBonds === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getBonds"
      >
        Obtener bonos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.saveBond === 'N'
        "
        v-model="newPermissions.permissions.humanResources.saveBond"
      >
        Añadir bonos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.editBond === 'N'
        "
        v-model="newPermissions.permissions.humanResources.editBond"
      >
        Actualizar bonos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.activateBond === 'N'
        "
        v-model="newPermissions.permissions.humanResources.activateBond"
      >
        Activar bonos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.disableBond === 'N'
        "
        v-model="newPermissions.permissions.humanResources.disableBond"
      >
        Desactivar bonos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.deleteBond === 'N'
        "
        v-model="newPermissions.permissions.humanResources.deleteBond"
      >
        Borrar bonos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonalComissions === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.getPersonalComissions
        "
      >
        Obtener comisiones personales
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getAllComissions === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getAllComissions"
      >
        Obtener todas las comisiones
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.addIncidence === 'N'
        "
        v-model="newPermissions.permissions.humanResources.addIncidence"
      >
        Añadir incidencias
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonalInventory === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getPersonalInventory"
      >
        Obtener inventario del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonalInventroy === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.savePersonalInventroy
        "
      >
        Añadir inventario del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.editPersonalInventory === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.editPersonalInventory
        "
      >
        Actualizar inventario del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.deletePersonalInventory ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.deletePersonalInventory
        "
      >
        Borrar inventario del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonalLoans === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getPersonalLoans"
      >
        Obtener prestamos del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonalLoan === 'N'
        "
        v-model="newPermissions.permissions.humanResources.savePersonalLoan"
      >
        Añadir prestamos del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonalLoanPayment ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.savePersonalLoanPayment
        "
      >
        Añadir pagos a prestamos del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.changePersonalLoanStatus ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.changePersonalLoanStatus
        "
      >
        Cambiar estatus de prestamos del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonalExtraHours ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.savePersonalExtraHours
        "
      >
        Añadir horas extra del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonalExtraHours === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.getPersonalExtraHours
        "
      >
        Obtener horas extra del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.deletePersonalExtraHours ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.deletePersonalExtraHours
        "
      >
        Borrar horas extra del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonalVacations === 'N'
        "
        v-model="newPermissions.permissions.humanResources.getPersonalVacations"
      >
        Obtener vacciones del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonalVacations === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.savePersonalVacations
        "
      >
        Añadir vacciones del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.deletePersonalVacations ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.deletePersonalVacations
        "
      >
        Borrar vacciones del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.getPersonalMissingHours ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.getPersonalMissingHours
        "
      >
        Obtener faltas del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources.savePersonalMissingHours ===
            'N'
        "
        v-model="
          newPermissions.permissions.humanResources.savePersonalMissingHours
        "
      >
        Añadir faltas del personal
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.humanResources.allPersonal === 'N' ||
          companyPaymentPlanModules.humanResources
            .deletePersonalMissingHours === 'N'
        "
        v-model="
          newPermissions.permissions.humanResources.deletePersonalMissingHours
        "
      >
        Borrar faltas del personal
      </b-checkbox>
    </div>

    <div class="permissions-group-container">
      <div class="permissions-title-container">
        <b-menu-item icon="cog" label="Configuración"></b-menu-item>
        <b-checkbox
          class="permissions-checkbox"
          true-value="S"
          false-value="N"
          :disabled="companyPaymentPlanModules.global.allConfiguration === 'N'"
          v-model="newPermissions.permissions.global.allConfiguration"
        >
        </b-checkbox>
      </div>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.main.stripePortal === 'N'
        "
        v-model="newPermissions.permissions.main.stripePortal"
      >
        Cambiar plan, información de pago y comprar timbres
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.getUsers === 'N'
        "
        v-model="newPermissions.permissions.global.getUsers"
      >
        Obtener usuarios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.saveUser === 'N'
        "
        v-model="newPermissions.permissions.global.saveUser"
      >
        Añadir usuarios
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.getPermissions === 'N'
        "
        v-model="newPermissions.permissions.global.getPermissions"
      >
        Obtener perfiles de permisos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.savePermissions === 'N'
        "
        v-model="newPermissions.permissions.global.savePermissions"
      >
        Añadir perfiles de permisos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.updatePermissions === 'N'
        "
        v-model="newPermissions.permissions.global.updatePermissions"
      >
        Actualizar perfiles de permisos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.deletePermissions === 'N'
        "
        v-model="newPermissions.permissions.global.deletePermissions"
      >
        Borrar perfiles de permisos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.getCompanyEmails === 'N'
        "
        v-model="newPermissions.permissions.global.getCompanyEmails"
      >
        Obtener correos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.saveCompanyEmail === 'N'
        "
        v-model="newPermissions.permissions.global.saveCompanyEmail"
      >
        Añadir correos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="
          newPermissions.permissions.global.allConfiguration === 'N' ||
          companyPaymentPlanModules.global.deleteCompanyEmail === 'N'
        "
        v-model="newPermissions.permissions.global.deleteCompanyEmail"
      >
        Borrar correos
      </b-checkbox>
      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        true-value="S"
        false-value="N"
        :disabled="newPermissions.permissions.global.clientSupport === 'N'"
        v-model="newPermissions.permissions.global.clientSupport"
      >
        Acceder a soporte al cliente
      </b-checkbox>
    </div>

    <div class="add-global-controls" v-if="!permissionsInformation">
      <b-button type="is-success" @click="saveNewPermissions()">
        Añadir perfil de permisos
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button">
        Regresar
      </b-button>
    </div>

    <b-tooltip
      multilined
      label="Los permisos del perfil de administrador no pueden editarse"
      v-if="permissionsInformation && permissionsInformation.name === 'Admin'"
    >
      <div class="add-global-controls">
        <b-button type="is-success" disabled>
          Actualizar perfil de permisos
        </b-button>
      </div>
    </b-tooltip>

    <div
      class="add-global-controls"
      v-if="permissionsInformation && permissionsInformation.name !== 'Admin'"
    >
      <b-button type="is-success" @click="updatePermissions()">
        Actualizar perfil de permisos
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "@/utils/fns";
import { emailServices } from "@/data/email-services";
import { fillObjectForm } from "@/utils/component-fns";
import { initialPermissions } from "@/data/initial-permissions";

export default {
  name: "AddPermissionsModal",
  props: ["permissionsInformation", "permissionsToClone"],
  components: {},
  data() {
    return {
      emailServices: emailServices,
      moment: moment,
      newPermissions: {
        ...initialPermissions,
      },
      newPermissionsErrors: {
        name: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.permissionsInformation) {
      // Fill the form with received information
      fillObjectForm(this.permissionsInformation, this.newPermissions);
      this.newPermissions._id = this.permissionsInformation._id;
    }

    if (this.permissionsToClone) {
      this.newPermissions.permissions = this.permissionsToClone.permissions;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveNewPermissions() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newPermissions,
          this.newPermissionsErrors
        );
        if (checkValidationErrors(this.newPermissionsErrors)) {
          let response = await this.$store.dispatch(
            "SAVEPERMISSIONS",
            this.newPermissions
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el perfil de permisos",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el perfil de permisos en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el perfil de permisos en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el perfil de permisos en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async updatePermissions() {
      try {
        let response = await this.$store.dispatch(
          "UPDATEPERMISSIONS",
          this.newPermissions
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente el perfil de permisos",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
          let allPromises = [];
          allPromises.push(this.$store.dispatch("GETPERMISSIONS"));
          await Promise.all(allPromises);
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el perfil de permisos en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el perfil de permisos en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    allArticlesValue() {
      return this.newPermissions.permissions.purchase.allArticles;
    },
    allWarehousesValue() {
      return this.newPermissions.permissions.purchase.allWarehouses;
    },
    allTransformationsValue() {
      return this.newPermissions.permissions.global.allTransformations;
    },
    allProvidersValue() {
      return this.newPermissions.permissions.purchase.allProviders;
    },
    allPurchaseOrdersValue() {
      return this.newPermissions.permissions.purchase.allPurchaseOrders;
    },
    allClientsValue() {
      return this.newPermissions.permissions.sales.allClients;
    },
    allPricesListsValue() {
      return this.newPermissions.permissions.sales.allPricesLists;
    },
    allArticlesPricesValue() {
      return this.newPermissions.permissions.sales.allArticlesPrices;
    },
    allAcountsValue() {
      return this.newPermissions.permissions.treasury.allAccounts;
    },
    allAccountsBankValue() {
      return this.newPermissions.permissions.banks.allAccounts;
    },
    allCalendarValue() {
      return this.newPermissions.permissions.productivity.allCalendar;
    },
    allConfigurationValue() {
      return this.newPermissions.permissions.global.allConfiguration;
    },
    allSalesValue() {
      return this.newPermissions.permissions.sales.allSales;
    },
    allProfessionalServicesValue() {
      return this.newPermissions.permissions.sales.allProfessionalServices;
    },
    allSatDocument() {
      return this.newPermissions.permissions.accounting.allSatDocument;
    },
    allVehicules() {
      return this.newPermissions.permissions.global.allVehicules;
    },
    allPersonal() {
      return this.newPermissions.permissions.humanResources.allPersonal;
    },
    allPos() {
      return this.newPermissions.permissions.sales.allPos;
    },
    allAccounting() {
      return this.newPermissions.permissions.accounting.allAccounting;
    },
    allDriverMaterialDeliveryValue() {
      return this.newPermissions.permissions.sales.allDriverMaterialDelivery;
    },
    allAnalyticsValue() {
      return this.newPermissions.permissions.main.allAnalytics;
    },
    allAccountingReportsValue() {
      return this.newPermissions.permissions.accounting.allAccountingReports;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
  watch: {
    allArticlesValue() {
      if (this.allArticlesValue === "N") {
        this.newPermissions.permissions.purchase.allArticles = "N";
        this.newPermissions.permissions.purchase.getArticles = "N";
        this.newPermissions.permissions.purchase.getArticleCostInformation =
          "N";
        this.newPermissions.permissions.purchase.saveArticle = "N";
        this.newPermissions.permissions.purchase.editArticle = "N";
        this.newPermissions.permissions.purchase.editArticlePresentation = "N";
        this.newPermissions.permissions.purchase.saveArticlePresentation = "N";
        this.newPermissions.permissions.global.getBrands = "N";
        this.newPermissions.permissions.global.saveBrands = "N";
        this.newPermissions.permissions.global.editBrands = "N";
        this.newPermissions.permissions.global.getMainCategories = "N";
        this.newPermissions.permissions.global.saveMainCategory = "N";
        this.newPermissions.permissions.global.editMainCategories = "N";
        this.newPermissions.permissions.global.getSCategories = "N";
        this.newPermissions.permissions.global.saveSCategory = "N";
        this.newPermissions.permissions.global.editSCategories = "N";
      }
    },
    allWarehousesValue() {
      if (this.allWarehousesValue === "N") {
        this.newPermissions.permissions.purchase.allWarehouses = "N";
        this.newPermissions.permissions.purchase.getWarehouse = "N";
        this.newPermissions.permissions.purchase.saveWarehouse = "N";
        this.newPermissions.permissions.purchase.addArticlesQuantity = "N";
        this.newPermissions.permissions.purchase.transferArticles = "N";
        this.newPermissions.permissions.purchase.confirmTransaction = "N";
        this.newPermissions.permissions.purchase.cancelTransaction = "N";
        this.newPermissions.permissions.purchase.editWarehouse = "N";
        this.newPermissions.permissions.purchase.editWarehouseMinMax = "N";
        this.newPermissions.permissions.global.sendMaterialMovement = "N";
        this.newPermissions.permissions.purchase.transferArticlesPending = "N";
        this.newPermissions.permissions.purchase.addArticlesQuantityPending =
          "N";
        this.newPermissions.permissions.main.useVirtualWarehouse = "N";
        this.newPermissions.permissions.purchase.resetSalesInventory = "N";
      }
    },
    allTransformationsValue() {
      if (this.allTransformationsValue === "N") {
        this.newPermissions.permissions.global.getFormulas = "N";
        this.newPermissions.permissions.global.saveFormula = "N";
        this.newPermissions.permissions.global.editFormula = "N";
        this.newPermissions.permissions.global.deleteFormula = "N";
        this.newPermissions.permissions.global.getArticleTransformation = "N";
        this.newPermissions.permissions.global.saveArticleTransformation = "N";
        this.newPermissions.permissions.global.cancelArticleTransformation =
          "N";
      }
    },
    allProvidersValue() {
      if (this.allProvidersValue === "N") {
        this.newPermissions.permissions.purchase.allProviders = "N";
        this.newPermissions.permissions.purchase.getProviders = "N";
        this.newPermissions.permissions.purchase.saveProvider = "N";
        this.newPermissions.permissions.purchase.saveProvidersPrices = "N";
        this.newPermissions.permissions.purchase.saveProviderArticleCost = "N";
        this.newPermissions.permissions.purchase.saveProviderCosts = "N";
        this.newPermissions.permissions.purchase.saveProviderDiscounts = "N";
        this.newPermissions.permissions.purchase.editProvider = "N";
        this.newPermissions.permissions.purchase.deleteProviderPrice = "N";
      }
    },
    allPurchaseOrdersValue() {
      if (this.allPurchaseOrdersValue === "N") {
        this.newPermissions.permissions.purchase.allPurchaseOrders = "N";
        this.newPermissions.permissions.purchase.savePurchaseOrder = "N";
        this.newPermissions.permissions.purchase.cancelPurchaseOrder = "N";
        this.newPermissions.permissions.purchase.getRequestOrders = "N";
        this.newPermissions.permissions.purchase.getPurchaseOrders = "N";
        this.newPermissions.permissions.purchase.printOrSendPurchaseOrder = "N";
        this.newPermissions.permissions.purchase.confirmRequestOrder = "N";
        this.newPermissions.permissions.purchase.receiveMaterial = "N";
        this.newPermissions.permissions.purchase.confirmAllMaterialReception =
          "N";
        this.newPermissions.permissions.purchase.editRequestOrders = "N";
        this.newPermissions.permissions.purchase.deleteRequestOrders = "N";
        this.newPermissions.permissions.purchase.editExpense = "N";
        this.newPermissions.permissions.purchase.authorizeExpense = "N";
        this.newPermissions.permissions.purchase.allPurchaseRevision = "N";
      }
    },
    allClientsValue() {
      if (this.allClientsValue === "N") {
        this.newPermissions.permissions.sales.getClients = "N";
        this.newPermissions.permissions.sales.saveClient = "N";
        this.newPermissions.permissions.sales.getCommercialBusiness = "N";
        this.newPermissions.permissions.sales.saveCommercialBusiness = "N";
        this.newPermissions.permissions.sales.editClient = "N";
        this.newPermissions.permissions.sales.editClientCredit = "N";
        this.newPermissions.permissions.sales.editClientLists = "N";
        this.newPermissions.permissions.sales.editCommercialBusiness = "N";
        this.newPermissions.permissions.sales.getClientsUnlocks = "N";
        this.newPermissions.permissions.sales.saveClientUnlock = "N";
        this.newPermissions.permissions.sales.deleteClientsUnlocks = "N";
        this.newPermissions.permissions.treasury.generateClientBalanceReport =
          "N";
        this.newPermissions.permissions.treasury.addClientManualBalanceEntry =
          "N";
        this.newPermissions.permissions.treasury.editClientCredit = "N";
      }
    },
    allPricesListsValue() {
      if (this.allPricesListsValue === "N") {
        this.newPermissions.permissions.sales.allPricesLists = "N";
        this.newPermissions.permissions.sales.getPriceLists = "N";
        this.newPermissions.permissions.sales.getSecretPriceLists = "N";
        this.newPermissions.permissions.sales.getPriceListsMargin = "N";
        this.newPermissions.permissions.sales.savePriceList = "N";
        this.newPermissions.permissions.sales.editPriceList = "N";
        this.newPermissions.permissions.sales.deletePriceList = "N";
      }
    },
    allArticlesPricesValue() {
      if (this.allArticlesPricesValue === "N") {
        this.newPermissions.permissions.sales.updatePriceListArticles = "N";
        this.newPermissions.permissions.sales.savePricesListCosts = "N";
        this.newPermissions.permissions.sales.savePricesListDiscounts = "N";
      }
    },
    allAcountsValue() {
      if (this.allAcountsValue === "N") {
        this.newPermissions.permissions.treasury.getExpenses = "N";
        this.newPermissions.permissions.treasury.addExpense = "N";
        this.newPermissions.permissions.treasury.authorizeExpense = "N";
        this.newPermissions.permissions.treasury.cancelExpense = "N";
        this.newPermissions.permissions.treasury.getPayments = "N";
        this.newPermissions.permissions.treasury.addPayment = "N";
        this.newPermissions.permissions.treasury.useAdvancedPayment = "N";
        this.newPermissions.permissions.treasury.cancelPayment = "N";
        this.newPermissions.permissions.treasury.addIncome = "N";
        this.newPermissions.permissions.treasury.cancelIncome = "N";
        this.newPermissions.permissions.treasury.getIncomes = "N";
        this.newPermissions.permissions.treasury.addAdvancedPayment = "N";
        this.newPermissions.permissions.treasury.cancelAdvancedPayment = "N";
        this.newPermissions.permissions.treasury.getAdvancedPayments = "N";
        this.newPermissions.permissions.treasury.editExpense = "N";
        this.newPermissions.permissions.treasury.editAdvancedPayment = "N";
        this.newPermissions.permissions.treasury.addManualMovementExpense = "N";
        this.newPermissions.permissions.treasury.editExpenseBasicInformation =
          "N";
        this.newPermissions.permissions.treasury.addManualMovementIncome = "N";
        this.newPermissions.permissions.treasury.editIncomeBasicInformation =
          "N";
        this.newPermissions.permissions.treasury.removeMissingAccountsIncomes =
          "N";
        this.newPermissions.permissions.treasury.removeMissingAccountsExpenses =
          "N";
        this.newPermissions.permissions.treasury.removeMissingPaymentsProviders =
          "N";
        this.newPermissions.permissions.treasury.removeMissingPaymentsClients =
          "N";
      }
    },
    allAccountsBankValue() {
      if (this.allAccountsBankValue === "N") {
        this.newPermissions.permissions.banks.getBanks = "N";
        this.newPermissions.permissions.banks.saveBank = "N";
        this.newPermissions.permissions.belvo.connecToBelvo = "N";
        this.newPermissions.permissions.banks.saveBankAccounts = "N";
        this.newPermissions.permissions.banks.getBankAccounts = "N";
        this.newPermissions.permissions.banks.saveBankAccountsMovements = "N";
        this.newPermissions.permissions.banks.synchBankAccountsMovements = "N";
        this.newPermissions.permissions.banks.synchBankAccountBalance = "N";
        this.newPermissions.permissions.banks.consolidateMovements = "N";
        this.newPermissions.permissions.banks.editBanks = "N";
        this.newPermissions.permissions.banks.cancelMovement = "N";
        this.newPermissions.permissions.banks.changeShowInAccounts = "N";
      }
    },
    allCalendarValue() {
      if (this.allCalendarValue === "N") {
        this.newPermissions.permissions.productivity.getEvents = "N";
        this.newPermissions.permissions.productivity.saveEvent = "N";
        this.newPermissions.permissions.productivity.editEvent = "N";
        this.newPermissions.permissions.productivity.deleteEvents = "N";
      }
    },
    allConfigurationValue() {
      if (this.allConfigurationValue === "N") {
        this.newPermissions.permissions.global.getUsers = "N";
        this.newPermissions.permissions.global.saveUser = "N";
        this.newPermissions.permissions.global.getPermissions = "N";
        this.newPermissions.permissions.global.savePermissions = "N";
        this.newPermissions.permissions.global.updatePermissions = "N";
        this.newPermissions.permissions.global.deletePermissions = "N";
        this.newPermissions.permissions.global.getCompanyEmails = "N";
        this.newPermissions.permissions.global.saveCompanyEmail = "N";
        this.newPermissions.permissions.global.deleteCompanyEmail = "N";
        this.newPermissions.permissions.main.stripePortal = "N";
        this.newPermissions.permissions.global.clientSupport = "N";
      }
    },
    allSalesValue() {
      if (this.allSalesValue === "N") {
        this.newPermissions.permissions.sales.getQuotes = "N";
        this.newPermissions.permissions.sales.confirmQuote = "N";
        this.newPermissions.permissions.sales.saveSale = "N";
        this.newPermissions.permissions.sales.cancelSale = "N";
        this.newPermissions.permissions.sales.dailyCancelSale = "N";
        this.newPermissions.permissions.sales.getSales = "N";
        this.newPermissions.permissions.sales.savePaymentType = "N";
        this.newPermissions.permissions.sales.getDiscountConcepts = "N";
        this.newPermissions.permissions.sales.saveDiscountConcepts = "N";
        this.newPermissions.permissions.sales.saveClarification = "N";
        this.newPermissions.permissions.sales.cancelClarification = "N";
        this.newPermissions.permissions.sales.saveCreditNote = "N";
        this.newPermissions.permissions.sales.cancelCreditNote = "N";
        this.newPermissions.permissions.sales.editQuotes = "N";
        this.newPermissions.permissions.sales.deleteQuotes = "N";
        this.newPermissions.permissions.sales.printOrSendSaleQuote = "N";
        this.newPermissions.permissions.sales.getSaleMaterialDeliveries = "N";
        this.newPermissions.permissions.sales.sendSaleMaterial = "N";
        this.newPermissions.permissions.sales.saveMaterialDeliverySign = "N";
        this.newPermissions.permissions.sales.confirmAllMaterialDelivery = "N";
        this.newPermissions.permissions.global.sendMaterialDelivery = "N";
        this.newPermissions.permissions.sales.sendMaterialNegative = "N";
        this.newPermissions.permissions.sales.editMaterialDeliverySale = "N";
        this.newPermissions.permissions.sales.editPartialSaleOrder = "N";
        this.newPermissions.permissions.sales.editPartialSale = "N";
        this.newPermissions.permissions.sales.removeSaleBackOrder = "N";
      }
    },
    allProfessionalServicesValue() {
      if (this.allProfessionalServicesValue === "N") {
        this.newPermissions.permissions.sales.cancelProfessionalServicesSale =
          "N";
        this.newPermissions.permissions.sales.editProfessionalServicesSale =
          "N";
        this.newPermissions.permissions.sales.getProfesionalServicesSales = "N";
        this.newPermissions.permissions.sales.saveProfesionalServicesSale = "N";
        this.newPermissions.permissions.sales.getProfessionalService = "N";
        this.newPermissions.permissions.sales.editProfessionalService = "N";
        this.newPermissions.permissions.sales.saveProfessionalService = "N";
        this.newPermissions.permissions.global.sendEmailProfessionalServicesSale =
          "N";
      }
    },
    allSatDocument() {
      if (this.allSatDocument === "N") {
        this.newPermissions.permissions.accounting.connectSatAccount = "N";
        this.newPermissions.permissions.accounting.synchDocument = "N";
        this.newPermissions.permissions.accounting.getDocuments = "N";
        this.newPermissions.permissions.accounting.getDocumentsXML = "N";
      }
    },
    allVehicules() {
      if (this.allVehicules === "N") {
        this.newPermissions.permissions.global.getVehicules = "N";
        this.newPermissions.permissions.global.saveVehicule = "N";
        this.newPermissions.permissions.global.editVehicules = "N";
      }
    },
    allPersonal() {
      if (this.allPersonal === "N") {
        this.newPermissions.permissions.humanResources.getPersonal = "N";
        this.newPermissions.permissions.humanResources.savePersonal = "N";
        this.newPermissions.permissions.humanResources.deletePersonal = "N";
        this.newPermissions.permissions.humanResources.getOrganizationBoard =
          "N";
        this.newPermissions.permissions.humanResources.editOrganizationBoard =
          "N";
        this.newPermissions.permissions.humanResources.editPersonal = "N";
        this.newPermissions.permissions.humanResources.seeComissionsInformation =
          "N";
        this.newPermissions.permissions.humanResources.getBonds = "N";
        this.newPermissions.permissions.humanResources.saveBond = "N";
        this.newPermissions.permissions.humanResources.editBond = "N";
        this.newPermissions.permissions.humanResources.disableBond = "N";
        this.newPermissions.permissions.humanResources.activateBond = "N";
        this.newPermissions.permissions.humanResources.deleteBond = "N";
        this.newPermissions.permissions.humanResources.getAllComissions = "N";
        this.newPermissions.permissions.humanResources.getPersonalComissions =
          "N";
        this.newPermissions.permissions.humanResources.addIncidence = "N";
        this.newPermissions.permissions.humanResources.getPersonalInventory =
          "N";
        this.newPermissions.permissions.humanResources.savePersonalInventroy =
          "N";
        this.newPermissions.permissions.humanResources.editPersonalInventory =
          "N";
        this.newPermissions.permissions.humanResources.deletePersonalInventory =
          "N";
        this.newPermissions.permissions.humanResources.getPersonalLoans = "N";
        this.newPermissions.permissions.humanResources.savePersonalLoan = "N";
        this.newPermissions.permissions.humanResources.savePersonalLoanPayment =
          "N";
        this.newPermissions.permissions.humanResources.changePersonalLoanStatus =
          "N";
        this.newPermissions.permissions.humanResources.savePersonalExtraHours =
          "N";
        this.newPermissions.permissions.humanResources.getPersonalExtraHours =
          "N";
        this.newPermissions.permissions.humanResources.deletePersonalExtraHours =
          "N";
        this.newPermissions.permissions.humanResources.getPersonalVacations =
          "N";
        this.newPermissions.permissions.humanResources.savePersonalVacations =
          "N";
        this.newPermissions.permissions.humanResources.deletePersonalVacations =
          "N";
        this.newPermissions.permissions.humanResources.getPersonalMissingHours =
          "N";
        this.newPermissions.permissions.humanResources.savePersonalMissingHours =
          "N";
        this.newPermissions.permissions.humanResources.deletePersonalMissingHours =
          "N";
      }
    },
    allPos() {
      if (this.allPos === "N") {
        this.newPermissions.permissions.sales.allPos = "N";
        this.newPermissions.permissions.sales.getPos = "N";
        this.newPermissions.permissions.sales.savePos = "N";
        this.newPermissions.permissions.sales.updatePos = "N";
        this.newPermissions.permissions.sales.openPos = "N";
        this.newPermissions.permissions.sales.closePos = "N";
        this.newPermissions.permissions.sales.confirmPos = "N";
        this.newPermissions.permissions.sales.getPosSale = "N";
        this.newPermissions.permissions.sales.getPosCortes = "N";
        this.newPermissions.permissions.sales.authorizeCorte = "N";
        this.newPermissions.permissions.sales.noAuthorizeCorte = "N";
        this.newPermissions.permissions.sales.addManualMovement = "N";
        this.newPermissions.permissions.sales.getSecrets = "N";
        this.newPermissions.permissions.sales.saveSecret = "N";
        this.newPermissions.permissions.sales.editSecret = "N";
        this.newPermissions.permissions.sales.deleteSecrets = "N";
        this.newPermissions.permissions.sales.deleteMissingSale = "N";
        this.newPermissions.permissions.sales.saveMissingSale = "N";
        this.newPermissions.permissions.sales.savePosSale = "N";
        this.newPermissions.permissions.sales.saveQuotePos = "N";
        this.newPermissions.permissions.sales.deleteQuotePos = "N";
        this.newPermissions.permissions.sales.confirmQuotePos = "N";
        this.newPermissions.permissions.sales.openAgainPos = "N";
      }
    },
    allAccounting() {
      if (this.allAccounting === "N") {
        this.newPermissions.permissions.accounting.saveInvoice = "N";
        this.newPermissions.permissions.accounting.getInvoiceXML = "N";
        this.newPermissions.permissions.accounting.getInvoicePDF = "N";
        this.newPermissions.permissions.accounting.getAcuse = "N";
        this.newPermissions.permissions.accounting.saveInvoiceCreditNote = "N";
        this.newPermissions.permissions.accounting.getCreditNotePDF = "N";
        this.newPermissions.permissions.accounting.getCreditNoteXML = "N";
        this.newPermissions.permissions.accounting.getAcuseCreditNote = "N";
        this.newPermissions.permissions.accounting.saveInvoicePayment = "N";
        this.newPermissions.permissions.accounting.getPaymentPDF = "N";
        this.newPermissions.permissions.accounting.getPaymentXML = "N";
        this.newPermissions.permissions.accounting.getAcusePayment = "N";
        this.newPermissions.permissions.accounting.savePorteCard = "N";
        this.newPermissions.permissions.accounting.getPorteCardPDF = "N";
        this.newPermissions.permissions.accounting.getPorteCardXML = "N";
        this.newPermissions.permissions.accounting.cancelPorteCard = "N";
        this.newPermissions.permissions.accounting.getAcusePorteCard = "N";
        this.newPermissions.permissions.accounting.saveInvoiceProfessionalServicesSale =
          "N";
        this.newPermissions.permissions.accounting.getProfessionalServicesInvoicePDF =
          "N";
        this.newPermissions.permissions.accounting.getDailyInvoices = "N";
        this.newPermissions.permissions.accounting.getAccontableAccounts = "N";
        this.newPermissions.permissions.accounting.saveAccontableAccounts = "N";
      }
    },
    allDriverMaterialDeliveryValue() {
      if (this.allDriverMaterialDeliveryValue === "N") {
        this.newPermissions.permissions.sales.confirmAllDriverMaterialDelivery =
          "N";
        this.newPermissions.permissions.sales.saveDriverMaterialDeliverySign =
          "N";
        this.newPermissions.permissions.sales.downloadDriverMaterialDelivery =
          "N";
      }
    },
    allAnalyticsValue() {
      if (this.allAnalyticsValue === "N") {
        this.newPermissions.permissions.main.salesDate = "N";
        this.newPermissions.permissions.main.purchasesDate = "N";
        this.newPermissions.permissions.main.expensesDate = "N";
        this.newPermissions.permissions.main.incomesDate = "N";
        this.newPermissions.permissions.main.articlesListDate = "N";
        this.newPermissions.permissions.main.clientsListDate = "N";
        this.newPermissions.permissions.main.providersListDate = "N";
        this.newPermissions.permissions.main.allAnalytics = "N";
        this.newPermissions.permissions.main.getManualMovementsDate = "N";
        this.newPermissions.permissions.main.getCancellationsDate = "N";
        this.newPermissions.permissions.main.articlesListMoneyDate = "N";
        this.newPermissions.permissions.main.getPersonalSalesPurchases = "N";
        this.newPermissions.permissions.main.getCompanySalesArticlesDate = "N";
        this.newPermissions.permissions.main.getCompanyTransfersDate = "N";
      }
    },
    allAccountingReportsValue() {
      if (this.allAccountingReportsValue === "N") {
        this.newPermissions.permissions.accounting.getClientsBalancesInvoices =
          "N";
        this.newPermissions.permissions.accounting.getClientsAccountsInvoices =
          "N";
        this.newPermissions.permissions.accounting.getSalesNoPayments = "N";
        this.newPermissions.permissions.accounting.getClientsAccountsPayments =
          "N";
        this.newPermissions.permissions.accounting.getProvidersBalanceInvoices =
          "N";
        this.newPermissions.permissions.accounting.getProvidersAccountsDetails =
          "N";
        this.newPermissions.permissions.accounting.getProvidersAccountsPayments =
          "N";
        this.newPermissions.permissions.accounting.getCashSales = "N";
        this.newPermissions.permissions.accounting.getSalesXMLDifferences = "N";
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
