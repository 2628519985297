/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="addCompanyNames()"
          >Agregar nueva razón social</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de razones sociales extras: <b>{{ companyNames.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="companyNames"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="CompanyNames"
        :is-row-checkable="
          (row) => {
            return row.RFC;
          }
        "
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.RFC}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="NOMBRE"
      >
        <template #empty>
          <div class="has-text-centered">No hay razones sociales extra</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <AddCompanyNamesModal :companyNameInformation="props.row" />
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddCompanyNamesModal from "./AddCompanyNamesModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "CompanyNamesList",
  components: {
    AddCompanyNamesModal,
  },
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      columns: [
        {
          field: "NOMBRE_FAC",
          label: "Razón social sin tipo de sociedad",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CP",
          label: "Código psotal",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    addCompanyNames() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCompanyNamesModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    companyNames() {
      return this.$store.getters.COMPANY_NAMES.map((singleCompanyName) => {
        const companyNameWithProps = { ...singleCompanyName };

        companyNameWithProps.STATUS_FOR = "Activa";

        if (companyNameWithProps.STATUS === "PE") {
          companyNameWithProps.STATUS_FOR = "Desactivada";
        }

        if (companyNameWithProps.STATUS !== "PE") {
          companyNameWithProps.STATUS_FOR = "Activa";
        }

        return companyNameWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
